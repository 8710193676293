<template>
	<div :class="'map-container ' + (page.customWidth? '' : (page.customHeight ? 'w-100' : 'full-map'))" :style="(page.customHeight ? `height: ${height};` : '') + (page.customWidth ? `width: ${width};` : '')">
		<l-map :ref="refName" style="height: 100%; width:100%" :zoom="map.zoom" :center="map.center" :bounds="map.bounds" @mousemove="mapMove">
			<l-control-layers v-if="showLayerControl" position="topright"></l-control-layers>
			<l-tile-layer v-for="tileProvider in map.tileProviders"
						  :key="tileProvider.name"
						  :name="tileProvider.name"
						  :visible="tileProvider.visible"
						  :url="tileProvider.url"
						  :attribution="tileProvider.attribution"
						  layer-type="base" />
			<l-layer-group layer-type="overlay" name="Subbasins">
				<l-geo-json v-if="map.subbasins.geojson !== undefined" :geojson="map.subbasins.geojson" :options="mapSubbasinsOptions" :options-style="mapSubbasinsStyle"></l-geo-json>
			</l-layer-group>
			<l-layer-group v-if="map.streams.geojson !== undefined" layer-type="overlay" name="Streams">
				<l-geo-json :geojson="map.streams.geojson" :options="map.streams.options"></l-geo-json>
			</l-layer-group>
			<l-layer-group v-if="map.usgsStations.geojson !== null" layer-type="overlay" name="USGS Stations">
				<l-geo-json :geojson="map.usgsStations.geojson" :options="mapUsgsOptions"></l-geo-json>
			</l-layer-group>
			<l-control position="bottomleft" v-if="height == 0">
				<div v-if="map.currentCoords !== undefined">Cursor position: {{map.currentCoords.lat | number(3)}}, {{map.currentCoords.lng | number(3)}}</div>
			</l-control>
			<l-layer-group ref="routeLayer" :visible="!page.loading" v-if="projectMap.connections.length > 1" layer-type="overlay" name="Route">
				<l-polyline v-for="(c, i) in polylines" :key="i" :lat-lngs="c.lineCoordinates" color="green"></l-polyline>
				<v-polyline-decorator :paths="routeCoordinates" :patterns="map.patterns"></v-polyline-decorator>
			</l-layer-group>
			<l-control v-if="showLayerControl" position="bottomright">
				<b-button v-if="false" variant="dark" size="sm" @click="toggleColors" style="font-size:0.8rem">Toggle Contrast</b-button>
				<b-dropdown text="Options" variant="dark" size="sm" style="font-size:0.8rem" dropup right>
					<b-dropdown-item @click="toggleColors">Toggle contrast</b-dropdown-item>
					<b-dropdown-divider></b-dropdown-divider>
					<b-dropdown-item @click="page.labels.all = true; page.labels.startEnd = false">Show labels</b-dropdown-item>
					<b-dropdown-item @click="page.labels.all = false; page.labels.startEnd = false">Hide labels</b-dropdown-item>
					<b-dropdown-item @click="page.labels.all = false; page.labels.startEnd = true">Show labels for start/end only</b-dropdown-item>
					<b-dropdown-divider></b-dropdown-divider>
					<b-dropdown-item :href="projectMap.geoJsonUrls.subbasins" target="_blank">Download subbasins GeoJSON</b-dropdown-item>
					<b-dropdown-item :href="projectMap.geoJsonUrls.streams" target="_blank">Download streams GeoJSON</b-dropdown-item>
				</b-dropdown>
			</l-control>
		</l-map>
		<div v-if="page.loading" class="map-loading-overlay">
			<font-awesome-icon :icon="['fas', 'spinner']" spin size="3x" />
		</div>
		<div v-if="map.usgsStations.geojson !== null" class="mt-4">
			<save-button text="Download Results" :saving="page.saving" @click.native="getUsgsResultsCsv" variant="info"></save-button>
		</div>
	</div>
</template>

<script>
	import moment from 'moment';
	import L from 'leaflet';
	import { LMap, LTileLayer, LGeoJson, LControl, LPolyline, LControlLayers, LLayerGroup } from 'vue2-leaflet';
	import Vue2LeafletPolylineDecorator from 'vue2-leaflet-polylinedecorator';
	//import _ from 'underscore';
	import 'leaflet/dist/leaflet.css';
	var bbox = require('geojson-bbox');

	var __globalStartingSubs = [];
	var __globalColors = {};
	var __globalShowLabels = false;
	var __globalShowStartEndLabels = false;
	var __globalSelectedStations = [];

	export default {
		name: 'ProjectView',
		components: {
			LMap, LTileLayer, LGeoJson, LControl, LPolyline, LControlLayers, LLayerGroup, 'v-polyline-decorator': Vue2LeafletPolylineDecorator
		},
		props: {
			projectMap: {
				type: Object,
				required: true
			},
			showLayerControl: {
				type: Boolean,
				default: false
			},
			height: {
				type: String,
				default: '100%'
			},
			width: {
				type: String,
				default: '100%'
			},
			highlightSelected: {
				type: Boolean,
				default: false
			},
			selectMultiple: {
				type: Boolean,
				default: false
			},
			selectedSubbasins: {
				type: Array,
				default: () => []
			},
			refName: {
				type: String,
				default: 'leafmap'
			},
			showLabels: {
				type: Boolean,
				default: false
			},
			showAcres: {
				type: Boolean,
				default: false
			},
			usgsStations: {
				type: Object,
				default: null
			}
		},
		data() {
			return {
				page: {
					errors: [],
					loading: false,
					customHeight: this.height != '100%',
					customWidth: this.width != '100%',
					labels: {
						all: false,
						startEnd: false
					},
					saving: false
				},
				map: {
					zoom: 5,
					center: [39.8282, -98.5795],
					bounds: undefined,
					url: 'https://{s}.tile.osm.org/{z}/{x}/{y}.png',
					attribution: '&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors',
					tileProviders: [
						{
							name: 'Default map',
							visible: true,
							attribution: '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
							url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
						},
						{
							name: 'Topography map',
							visible: false,
							url: 'https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png',
							attribution: 'Map data: &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)'
						},
						{
							name: 'Satellite map',
							visible: false,
							url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
							attribution: 'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
						}
					],
					subbasins: {
						geojson: undefined
					},
					streams: {
						geojson: undefined,
						options: {
							style: {
								weight: 1,
								opacity: 1,
								color: '#006da0',
								dashArray: '0'
							}
						}
					},
					currentCoords: undefined,
					route: [],
					patterns: [
						{ offset: '50%', repeat: 0, symbol: L.Symbol.arrowHead({ pixelSize: 15, polygon: false, pathOptions: { color: 'green', stroke: true } }) }
					],
					usgsStations: {
						geojson: null
					}
				},
				startingSubs: this.selectedSubbasins,
				usgsResults: null
			}
		},
		async created() {
		},
		async mounted() {
			await this.get();
			if (this.projectMap.connections.length > 1)
				this.$refs.routeLayer.mapObject.remove();
			this.renderMap();
		},
		watch: {
			selectedSubbasins(newVal) {
				this.startingSubs = newVal;
				__globalStartingSubs = this.startingSubs;
			}
		},
		methods: {
			async get() {
				this.log(this.startingSubs);
				this.page.errors = [];
				this.page.loading = true;

				this.loadMapColors();
				this.map.streams.options.style.color = this.mapColors.stream;

				try {
					const subsResponse = await this.$httpRoot.get(this.projectMap.geoJsonUrls.subbasins);
					const streamsResponse = await this.$httpRoot.get(this.projectMap.geoJsonUrls.streams);

					this.map.subbasins.geojson = subsResponse.data;
					this.log(this.map.subbasins.geojson);
					this.map.streams.geojson = streamsResponse.data;
					this.map.route = this.projectMap.connections;

					//this.map.usgsStations.geojson = this.filteredUsgsStations();
					//this.log(__globalSelectedStations);
					//this.getUsgsResults(false);
				} catch (error) {
					this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			},
			async getUsgsResultsCsv() {
				this.page.saving = true;
				await this.getUsgsResults(false);

				var blob = new Blob([this.usgsResults], { type: 'text/csv;charset=utf-8;' });
				this.page.saving = false;

				var link = document.createElement("a");
				var url = URL.createObjectURL(blob);
				link.setAttribute("href", url);
				link.setAttribute("download", `usgs-stream-data-${moment().format('YYYY-MM-DD')}.csv`);
				link.style.visibility = 'hidden';
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			},
			async getUsgsResults(init = true) {
				if (__globalSelectedStations.length > 0) {
					if (init) {
						this.page.errors = [];
						this.page.loading = true;
					}
					let url = 'https://www.waterqualitydata.us/data/Result/search?mimeType=csv';

					let postData = {
						siteid: __globalSelectedStations
					};

					try {
						const response = await this.$httpRoot.post(url, postData);
						this.log(response);
						this.usgsResults = response.data;
					} catch (error) {
						this.page.errors = this.logError(error);
					}

					if (init) this.page.loading = false;
				}
			},
			mapMove(e) {
				this.map.currentCoords = e.latlng;
			},
			mapSubbasinsClick(e) {
				var sub = e.target.feature.properties;
				var item = { id: sub.id, name: sub.name };

				if (this.highlightSelected) {
					if (this.selectMultiple) {
						var index = this.startingSubs.findIndex(x => x.id == sub.id);
						if (index === -1) {
							this.startingSubs.push(item);
						} else {
							this.removeStartingSub(sub.id);
						}
					} else {
						this.startingSubs = [item];
					}
					__globalStartingSubs = this.startingSubs;
				}

				this.$emit('subbasin-click', this.startingSubs);
			},
			removeStartingSub(id) {
				if (this.highlightSelected) {
					this.startingSubs = this.startingSubs.filter(function (el) { return el.id != id; });
					__globalStartingSubs = this.startingSubs;
				}
			},
			renderMap: function() {
				window.dispatchEvent(new Event('resize'));
				var ref = this.$refs[this.refName];
				var self = this;
				this.$nextTick(function () {
					ref.mapObject.invalidateSize(true);
					self.setMapBounds();
				});
			},
			setMapBounds() {
				this.map.bounds = L.latLngBounds(this.projectMap.watershedBounds);
				if (this.map.subbasins.geojson) {
					var bounds = bbox(this.map.subbasins.geojson);
					this.map.bounds = L.latLngBounds([[bounds[3], bounds[2]], [bounds[1], bounds[0]]]);
				} else {
					this.map.bounds = L.latLngBounds(this.projectMap.watershedBounds);
				}
			},
			toggleColors() {
				this.setMapColors(!this.mapColors.highContrast);
				this.map.streams.options.style.color = this.mapColors.stream;
			},
			isMarkerInsidePolygon(point, coords) {
				let poly = L.polygon(coords);
				let marker = L.marker(point);

				var inside = false;
				var x = marker.getLatLng().lat, y = marker.getLatLng().lng;
				for (var ii = 0; ii < poly.getLatLngs().length; ii++) {
					var polyPoints = poly.getLatLngs()[ii];
					for (var i = 0, j = polyPoints.length - 1; i < polyPoints.length; j = i++) {
						var xi = polyPoints[i].lat, yi = polyPoints[i].lng;
						var xj = polyPoints[j].lat, yj = polyPoints[j].lng;

						var intersect = ((yi > y) != (yj > y))
							&& (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
						if (intersect) inside = !inside;
					}
				}

				return inside;
			},
			filteredUsgsStations() {
				if (this.usgsStations === null) return null;
				if (this.map.subbasins.geojson === undefined) return this.usgsStations;

				let matchingFeatures = [];
				let subs = this.map.subbasins.geojson.features;

				for (let station of this.usgsStations.features) {
					let pointCoords = station.geometry.coordinates;
					for (let sub of subs) {
						let subCoords = sub.geometry.coordinates;
						let isInside = this.isMarkerInsidePolygon(pointCoords, subCoords);
						if (isInside) {
							matchingFeatures.push(station);
							__globalSelectedStations.push(station.properties.MonitoringLocationIdentifier);
						}
					}
				}

				return {
					type: this.usgsStations.type,
					features: matchingFeatures
				};
			}
		},
		computed: {
			polylines() {
				return this.projectMap.connections.filter(function (el) { return el.lineCoordinates[1].length > 0; })
			},
			mapSubbasinsOptions() {
				return {
					onEachFeature: this.mapSubbasinsOnEachFeature
				};
			},
			mapSubbasinsStyle() {
				__globalStartingSubs = this.startingSubs;
				__globalColors = this.mapColors;
				return (feature) => {
					var fillColor = feature.properties.isDownstream ? __globalColors.downstream : (feature.properties.isNearbyOnly ? __globalColors.nearby : __globalColors.subbasin);
					var index = __globalStartingSubs.findIndex(x => x.id == feature.properties.id);
					if (index > -1)
						fillColor = __globalColors.selected;

					return {
						fillColor: fillColor,
						weight: 1,
						opacity: 0.8,
						color: 'black',
						dashArray: '0',
						fillOpacity: feature.properties.isDownstream ? 0.9 : 0.5
					};
				};
			},
			mapSubbasinsOnEachFeature() {
				__globalShowLabels = this.page.labels.all;
				__globalShowStartEndLabels = this.page.labels.startEnd;
				return (feature, layer) => {
					let showThisOne = false;
					if (__globalShowStartEndLabels) {
						showThisOne = feature.properties.isDownstream || feature.properties.id === this.map.subbasins.geojson.features[0].properties.id;
					}

					if (this.showLabels || __globalShowLabels || showThisOne) {
						layer.bindTooltip(
							feature.properties.name,
							{
								permanent: true,
								direction: 'center',
								className: 'perma-label'
							}
						);
					} else {
						let streamName = feature.properties.name === feature.properties.streams ? '' : feature.properties.streams;
						let area = this.toAcres(feature.properties.area, this.showAcres);
						let units = this.showAcres ? 'ac' : 'km<sup>2</sup>';
						layer.bindTooltip(
							'<div>' + feature.properties.name + '</div>' +
							'<div>' + streamName + '</div>' +
							'<div class="mt-2">' + this.numberWithCommas(area.toFixed(2)) + units + ' area</div>',
							{ permanent: false, sticky: true });
					}

					layer.on({
						click: this.mapSubbasinsClick
					})
				}
			},
			routeCoordinates() {
				var plArray = [];
				for (var i = 0; i < this.projectMap.connections.length; i++) {
					if (this.projectMap.connections[i].lineCoordinates[1].length > 0)
						plArray.push(this.projectMap.connections[i].lineCoordinates);
				}
				return plArray;
			},
			mapUsgsOptions() {
				return {
					onEachFeature: this.mapUsgsOnEachFeature,
					pointToLayer: this.mapUsgsPointToLayer
				};
			},
			mapUsgsOnEachFeature() {
				return (feature, layer) => {
					layer.bindTooltip(
						'<div>' + feature.properties.MonitoringLocationIdentifier + '</div>' +
						'<div>' + feature.properties.MonitoringLocationName + '</div>',
						{ permanent: false, sticky: true });
				}
			},
			mapUsgsPointToLayer() {
				return (feature, latlng) => {
					let fillColor = __globalSelectedStations.includes(feature.properties.MonitoringLocationIdentifier) ? '#0068C1' : '#0068C1';

					return L.circleMarker(latlng, {
						radius: 6,
						fillColor: fillColor,
						color: '#000',
						weight: 1,
						opacity: 1,
						fillOpacity: 0.8
					});
				}
			}
		}
	}
</script>
