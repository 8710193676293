<template>
	<auth-container :page="page" require-admin>
		<h2 class="mb-4">Add a new user</h2>
		<error-list :errors="page.saveErrors"></error-list>

		<b-form @submit.prevent="save">
			<b-form-group label="User name" :invalid-feedback="requiredFeedback($v.form.userName)">
				<b-form-input v-model.trim="$v.form.userName.$model" type="text" autofocus :state="getValidState($v.form.userName)"></b-form-input>
			</b-form-group>

			<b-form-group label="Email">
				<b-form-input v-model.trim="$v.form.email.$model" type="email" :state="getValidState($v.form.email)"></b-form-input>
				<b-form-invalid-feedback v-if="!$v.form.email.required">Required</b-form-invalid-feedback>
				<b-form-invalid-feedback v-if="!$v.form.email.email">Please enter a valid email address</b-form-invalid-feedback>
			</b-form-group>

			<b-row>
				<b-col md>
					<b-form-group label="First name" :invalid-feedback="requiredFeedback($v.form.firstName)">
						<b-form-input v-model.trim="$v.form.firstName.$model" type="text" :state="getValidState($v.form.firstName)"></b-form-input>
					</b-form-group>
				</b-col>
				<b-col md>
					<b-form-group label="Last name" :invalid-feedback="requiredFeedback($v.form.lastName)">
						<b-form-input v-model.trim="$v.form.lastName.$model" type="text" :state="getValidState($v.form.lastName)"></b-form-input>
					</b-form-group>
				</b-col>
			</b-row>

			<b-form-group label="Company or organization" :invalid-feedback="requiredFeedback($v.form.affiliation)">
				<b-form-input v-model.trim="$v.form.affiliation.$model" type="text" :state="getValidState($v.form.affiliation)"></b-form-input>
			</b-form-group>

			<b-form-group label="Category" :invalid-feedback="requiredFeedback($v.form.organizationCategory)">
				<b-form-select v-model.trim="$v.form.organizationCategory.$model" :options="location.organizationCategories" :state="getValidState($v.form.organizationCategory)"></b-form-select>
			</b-form-group>

			<b-form-group label="What is your reason for trying SELECT?" :invalid-feedback="requiredFeedback($v.form.reasonForUse)">
				<b-form-input v-model.trim="$v.form.reasonForUse.$model" type="text" :state="getValidState($v.form.reasonForUse)"></b-form-input>
			</b-form-group>

			<b-form-group label="In what country are you located?" :invalid-feedback="requiredFeedback($v.form.country)">
				<b-form-select v-model.trim="$v.form.country.$model" :options="location.countries" :state="getValidState($v.form.country)"></b-form-select>
			</b-form-group>

			<b-form-group v-if="form.country === 'United States'" label="Select your state or territory" :invalid-feedback="requiredFeedback($v.form.state)">
				<b-form-select v-model.trim="$v.form.state.$model" :options="location.states" :state="getValidState($v.form.state)"></b-form-select>
			</b-form-group>

			<b-form-group label="Password" :description="globals.passwordRequirements">
				<b-form-input v-model.trim="$v.form.password.$model" type="password" :state="getValidState($v.form.password)"></b-form-input>
				<b-form-invalid-feedback v-if="!$v.form.password.required">Required</b-form-invalid-feedback>
				<b-form-invalid-feedback v-if="!$v.form.password.strongPassword">{{globals.passwordRequirements}}</b-form-invalid-feedback>
			</b-form-group>

			<b-form-group class="mt-4">
				<b-checkbox v-model.trim="$v.form.isAdmin.$model">Is system administrator?</b-checkbox>
			</b-form-group>

			<fixed-action-bar :cols-lg="10" :offset-lg="2">
				<save-button :saving="page.saving" class="mr-2" :disabled="!$v.$anyDirty" />
				<back-button class="btn btn-secondary mr-2" />
			</fixed-action-bar>
		</b-form>
	</auth-container>
</template>

<script>
	import { required, email } from 'vuelidate/lib/validators';

	export default {
		name: 'UserCreate',
		data() {
			return {
				page: {
					errors: [],
					loading: false,
					showLogin: false,
					saving: false,
					saveErrors: []
				},
				form: {
					userName: null,
					email: null,
					password: null,
					firstName: null,
					lastName: null,
					title: null,
					isAdmin: false
				},
				location: {
					countries: [],
					states: [],
					organizationCategories: []
				}
			}
		},
		validations: {
			form: {
				email: { email, required },
				userName: { required },
				firstName: { required },
				lastName: { required },
				password: {
					strongPassword(password) {
						return (
							this.form.usingAccessKey || (
							/[a-z]/.test(password) && // checks for a-z
							/[0-9]/.test(password) && // checks for 0-9
							/\W|_/.test(password) && // checks for special char
							password.length >= 10)
						);
					},
					required
				},
				affiliation: {},
				organizationCategory: {},
				reasonForUse: {},
				country: {},
				state: {},
				isAdmin: {}
			}
		},
		async created() {
			await this.get();
		},
		watch: {
			'$route': 'get'
		},
		methods: {
			async get() {
				this.page.errors = [];
				this.page.loading = true;
				this.userID = this.$route.params.id;

				try {
					const response = await this.$http.get(`users/location`, this.getTokenHeader());
					this.log(response.data);
					this.location = response.data;
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			},
			async save() {
				this.page.saveErrors = [];
				this.page.saving = true;

				if (this.$v.$invalid) {
					this.page.saveErrors.push('Please fix the errors below and try again.');
				} else {
					try {
						const response = await this.$http.post('users', this.form, this.getTokenHeader());
						this.$router.push({ name: 'UserEdit', params: { id: response.data } }).catch(err => { this.log(err); });
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.saveErrors = this.logError(error);
					}
				}

				this.page.saving = false;
			}
		}
	}
</script>