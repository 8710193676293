<template>
	<div>
		<page-loading :loading="page.loading"></page-loading>
		<div v-if="!page.loading" class="max-w-400 mx-auto py-4">
			<error-list :errors="page.errors"></error-list>

			<b-toast id="changes-saved" variant="success" solid title="Changes saved" toaster="b-toaster-top-center">
				Your account has been reset! Please log in below.
			</b-toast>

			<div v-if="false">
				<div>
					Your account has been reset! Please log in below.
				</div>
				<login-form :redirect-route="page.redirectRoute"></login-form>
			</div>
			<div v-else>
				<b-form @submit.prevent="resetPassword" class="my-4">
					<b-form-group label="Email address" :invalid-feedback="requiredFeedback($v.form.email)">
						<b-form-input v-model="form.email" type="email" :state="getValidState($v.form.email)"></b-form-input>
						<b-form-invalid-feedback v-if="!$v.form.email.required">Required</b-form-invalid-feedback>
						<b-form-invalid-feedback v-if="!$v.form.email.email">Please enter a valid email address</b-form-invalid-feedback>
					</b-form-group>

					<b-form-group label="Enter a new password" :description="globals.passwordRequirements">
						<b-form-input v-model="form.password" type="password" :state="getValidState($v.form.password)"></b-form-input>
						<b-form-invalid-feedback v-if="!$v.form.password.required">Required</b-form-invalid-feedback>
						<b-form-invalid-feedback v-if="!$v.form.password.strongPassword">{{globals.passwordRequirements}}</b-form-invalid-feedback>
					</b-form-group>

					<b-form-group label="Confirm your new password">
						<b-form-input v-model="form.comparePassword" type="password" :state="getValidState($v.form.comparePassword)"></b-form-input>
						<b-form-invalid-feedback v-if="!$v.form.comparePassword.required">Required</b-form-invalid-feedback>
						<b-form-invalid-feedback v-if="!$v.form.comparePassword.sameAsPassword">Passwords must match</b-form-invalid-feedback>
					</b-form-group>

					<p>
						After clicking the button below, you'll be redirected to the log-in form if successful. Enter your user name provided in the account reset email we sent, and the new password you entered above to log in.
					</p>

					<div class="mt-4">
						<save-button :saving="page.saving" text="Reset password" size="lg" />
					</div>
				</b-form>
			</div>
		</div>
	</div>
</template>

<script>
	import { required, email, sameAs } from 'vuelidate/lib/validators';

	export default {
		name: 'AccountConfirm',
		data() {
			return {
				page: {
					errors: [],
					loading: false,
					saving: false,
					redirectRoute: { name: 'ProjectList' }
				},
				form: {
					code: '',
					email: '',
					password: '',
					comparePassword: ''
				},
				account: {
					reset: false
				}
			}
		},
		validations: {
			form: {
				email: { required, email },
				password: {
					required,
					strongPassword(password) {
						return (
							/[a-z]/.test(password) && // checks for a-z
							/[0-9]/.test(password) && // checks for 0-9
							/\W|_/.test(password) && // checks for special char
							password.length >= 10
						);
					}
				},
				comparePassword: { required, sameAsPassword: sameAs('password') }
			}
		},
		methods: {
			resetPassword: async function () {
				this.page.errors = [];
				this.page.saving = true;

				if (this.$v.$invalid) {
					this.page.errors.push('Please fix the errors below and try again.');
				} else {
					var data = {
						code: this.$route.params.code,
						email: this.form.email,
						password: this.form.password
					}

					try {
						const response = await this.$http.post('account/resetpassword', data);
						this.log(response);
						this.account.reset = true;
						this.$bvToast.show('changes-saved');
						this.$router.push(this.page.redirectRoute).catch(err => { this.log(err); });
					} catch (error) {
						this.page.errors = this.logError(error);
					}
				}

				this.page.saving = false;
			}
		}
	}
</script>
