<template>
	<div>
		<b-container class="py-4" v-if="$route.name === 'ProjectView'">
			<auth-container :page="page">
				<b-form @submit.prevent="save">
					<h2 class="mb-4">
						<router-link to="/projects" class="text-reset text-decoration-none mr-2">Projects</router-link>
						<font-awesome-icon :icon="['fas', 'angle-right']" class="text-gray-400" />
						{{project.name}}
					</h2>
					<error-list :errors="page.saveErrors"></error-list>

					<div class="position-relative">
						<div style="right:0" class="position-absolute bg-white p-1 d-md-none">
							<b-button size="sm" variant="light" @click="scrollLeft(100)" class="border mr-1" title="Scroll tabs left"><font-awesome-icon icon="chevron-left" /></b-button>
							<b-button size="sm" variant="light" @click="scrollRight(100)" class="border" title="Scroll tabs right"><font-awesome-icon icon="chevron-right" /></b-button>
						</div>
						<b-tabs v-model="page.tabIndex" content-class="mt-4" class="page-nav-tabs tabs-scroll">
							<b-tab title="Watershed" @click="page.watershedMapKey++">
								<b-row>
									<b-col md>
										<table class="table table-definitions border-bottom mb-3">
											<tbody>
												<tr>
													<th>Name</th>
													<td>{{project.name}}</td>
												</tr>
												<tr>
													<th>Watershed</th>
													<td>{{project.dataset}}, {{project.endingSubbasin}}</td>
												</tr>
											</tbody>
										</table>
									</b-col>
									<b-col md>
										<table class="table table-definitions border-bottom mb-3">
											<tbody>
												<tr>
													<th>Subbasins</th>
													<td colspan="2">{{project.numSubbasins | number(0)}}</td>
												</tr>
												<tr>
													<th>Total area</th>
													<td>
														{{toAcres(project.totalArea, page.showAcres) | number(2)}}
														<span v-if="page.showAcres">ac</span>
														<span v-else>km<sup>2</sup></span>
													</td>
													<td class="text-right">
														<b-form-checkbox v-model="page.showAcres" switch @change="updateCharts">
															Show units in acres
														</b-form-checkbox>
													</td>
												</tr>
											</tbody>
										</table>
									</b-col>
								</b-row>

								<project-map :key="page.watershedMapKey" :project-map="project.map" height="350px" show-layer-control :show-acres="page.showAcres"></project-map>

								<hr class="my-4" />

								<h3 class="mt-4">Land Cover</h3>
								<b-tabs content-class="mt-3">
									<b-tab title="Column Chart" active>
										<highcharts :options="charts.landCover"></highcharts>
									</b-tab>
									<b-tab title="Pie Chart">
										<highcharts :options="charts.landCoverPie"></highcharts>
									</b-tab>
								</b-tabs>

								<hr class="my-4" />

								<div class="d-flex mb-2">
									<div><h3 class="my-0">Counties</h3></div>
									<div class="ml-auto text-right">
										<json-to-csv :json-data="countiesInAcres" :filename="`select-counties-${toValidFileName(project.name)}`"></json-to-csv>
									</div>
								</div>
								<b-table striped hover :items="counties" :fields="tables.counties.fields"></b-table>

								<b-row class="mt-5">
									<b-col md="5">
										<highcharts :options="charts.percentWatershedInCounty"></highcharts>
									</b-col>
									<b-col md="5" offset="1">
										<highcharts :options="charts.percentCountyInWatershed"></highcharts>
									</b-col>
								</b-row>

							</b-tab>
							<b-tab title="Pets">
								<b-row>
									<b-col sm>
										<b-form-group label="Dog density" :invalid-feedback="requiredFeedback($v.project.inputs.dogDensity)">
											<b-form-input v-model.number.trim="$v.project.inputs.dogDensity.$model" type="number" required step="any" :state="getValidState($v.project.inputs.dogDensity)"></b-form-input>
										</b-form-group>
										<b-form-group label="Cat density" :invalid-feedback="requiredFeedback($v.project.inputs.catDensity)">
											<b-form-input v-model.number.trim="$v.project.inputs.catDensity.$model" type="number" required step="any" :state="getValidState($v.project.inputs.catDensity)"></b-form-input>
										</b-form-group>
									</b-col>
									<b-col sm>
										<b-form-group label="Fecal coliform to E. coli conversion factor" :invalid-feedback="requiredFeedback($v.project.inputs.petsFecalToEcoliRatio)">
											<b-form-input v-model.number.trim="$v.project.inputs.petsFecalToEcoliRatio.$model" type="number" required step="any" :state="getValidState($v.project.inputs.petsFecalToEcoliRatio)"></b-form-input>
										</b-form-group>
										<b-form-group label="Fecal coliform production rate (cfu/head/day)" :invalid-feedback="requiredFeedback($v.project.inputs.petsFecalProductionRate)">
											<b-form-input v-model.number.trim="$v.project.inputs.petsFecalProductionRate.$model" type="number" required step="any" :state="getValidState($v.project.inputs.petsFecalProductionRate)"></b-form-input>
										</b-form-group>
									</b-col>
								</b-row>

								<page-loading :loading="page.saving"></page-loading>
								<div v-if="!page.saving && pets.table.length > 0">
									<hr class="my-4" />
									<map-visualizer :geo-json-urls="project.map.geoJsonUrls" :map-data="pets.maps"></map-visualizer>

									<div class="d-flex mb-2">
										<div><h3 class="my-0">Potential Loads by Subbasin</h3></div>
										<div class="ml-auto text-right">
											<json-to-csv :json-data="pets.table" :filename="`select-pets-load-${toValidFileName(project.name)}`"></json-to-csv>
										</div>
									</div>									
									<b-table responsive striped hover :items="pets.table" :fields="tables.pets.fields"></b-table>
								</div>
							</b-tab>
							<b-tab title="Livestock" @click="page.livestockMapPanel.key++">
								<p>
									<b-button type="button" variant="secondary" @click="page.defaultLivestockRates.show = true">Set Land Cover &amp; Get Suggested Stocking Rates</b-button>
								</p>

								<b-modal v-model="page.defaultLivestockRates.show" title="Suggested Stocking Rates" size="xl">
									<error-list :errors="page.defaultLivestockRates.errors"></error-list>

									<b-row>
										<b-col md>
											<div class="checklist-header">
												Select Land Cover Types
											</div>
											<div class="checklist">
												<b-form-checkbox-group v-model="selectedLivestockLandCover" stacked>
													<div class="item" v-for="(o, i) in landCoversNoOpenWater" :key="i">
														<b-form-checkbox :value="o.value">{{o.text}}</b-form-checkbox>
													</div>
												</b-form-checkbox-group>
											</div>
										</b-col>
										<b-col md>
											<div class="checklist-header">
												Apply Rates to Selected Animals
											</div>
											<div class="checklist">
												<b-form-checkbox-group v-model="page.defaultLivestockRates.animals" stacked>
													<div class="item"><b-form-checkbox value="Cattle">Cattle</b-form-checkbox></div>
													<div class="item"><b-form-checkbox value="Sheep">Sheep</b-form-checkbox></div>
													<div class="item"><b-form-checkbox value="Goats">Goats</b-form-checkbox></div>
													<div class="item"><b-form-checkbox value="Horses">Horses</b-form-checkbox></div>
												</b-form-checkbox-group>
											</div>

											<b-form-group label="OPTIONAL: Set Uniform Stocking Rate" class="mt-4" 
												description="Set your own stocking rate for the checked landuse and animals, applied to all subbasins" label-class="font-weight-bold">
												<b-form-input v-model.number="page.defaultLivestockRates.override" type="number" required step="any"></b-form-input>
											</b-form-group>
										</b-col>
									</b-row>

									<div slot="modal-footer">
										<save-button type="button" :saving="page.defaultLivestockRates.saving" @click.native="getDefaultLivestockRates" text="Get Rates &amp; Apply to Form" variant="success" :disabled="page.defaultLivestockRates.saving" />
										<b-button type="button" variant="secondary" @click="page.defaultLivestockRates.show = false" class="ml-1">Cancel</b-button>
									</div>
								</b-modal>

								<b-table-simple small responsive bordered class="mb-3 table-valign-m">
									<b-thead>
										<b-tr>
											<b-th variant="light" colspan="4"></b-th>
											<b-th variant="light" colspan="15">Stocking Rates (acres/head)</b-th>
										</b-tr>
										<b-tr>
											<b-th></b-th>
											<b-th variant="light" sticky-column>Animal</b-th>
											<b-th>Conv. Factor</b-th>
											<b-th>Production Rate</b-th>
											<b-th v-for="(c, k) in project.map.connections" :key="k" :class="c.fromID === livestockSelectedId && page.livestockMapPanel.show ? 'bg-lighter-blue' : ''" :id="`livestockSubHeader${c.fromID}`">
												{{c.fromName}}
											</b-th>
										</b-tr>
									</b-thead>
									<b-tbody>
										<b-tr v-for="(item, i) in project.inputs.livestockFecalNumbers" :key="i">
											<b-th><b-button variant="light" size="sm" title="Reset stocking rates to 0" @click="setLivestockRate(item.animal, 0)">Clear</b-button></b-th>
											<b-th variant="light" sticky-column>{{item.animal}}</b-th>
											<b-td>
												<b-form-input type="number" step="any" class="number-field"
															  v-model.number.trim="$v.project.inputs.livestockFecalNumbers.$each[i].fecalToEcoliRatio.$model"
															  :state="getValidState($v.project.inputs.livestockFecalNumbers.$each[i].fecalToEcoliRatio)"></b-form-input>
											</b-td>
											<b-td>
												<b-form-input type="number" step="any" class="big-number-field"
															  v-model.number.trim="$v.project.inputs.livestockFecalNumbers.$each[i].fecalProductionRate.$model"
															  :state="getValidState($v.project.inputs.livestockFecalNumbers.$each[i].fecalProductionRate)"></b-form-input>
											</b-td>
											<b-td v-for="(c, j) in project.map.connections" :key="j">
												<b-form-input type="number" step="any" class="number-field"
																@focus="setLivestockMapSubbasin(c.fromID, c.fromName)" 
															  v-model.number.trim="$v.project.inputs.subLivestockStockingRates.$each[getLivestockIndex(c.fromID, item.animal)].stockingRate.$model"
															  :state="getValidState($v.project.inputs.subLivestockStockingRates.$each[getLivestockIndex(c.fromID, item.animal)].stockingRate)"></b-form-input>
											</b-td>
										</b-tr>
									</b-tbody>
								</b-table-simple>
								
								<div class="mb-3">
									<b-collapse v-model="page.livestockMapPanel.show" class="mb-1">
										<project-map :key="page.livestockMapPanel.key" :project-map="project.map" height="300px"
													 :show-acres="page.showAcres"
													 highlight-selected
													 :selected-subbasins="page.livestockMapPanel.selected"
													 @subbasin-click="livestockMapClick"></project-map>
									</b-collapse>
									<b-button variant="primary" @click="toggleLivestockMap">Toggle Map</b-button>
								</div>

								<p class="mb-0">
									In the table above:
								</p>
								<ul>
									<li>Conv. Factor = Fecal coliform to E. coli conversion factor</li>
									<li>Production Rate = Fecal coliform production rate (cfu/head/day)</li>
								</ul>

								<page-loading :loading="page.saving"></page-loading>
								<div v-if="!page.saving && livestock.table.length > 0">
									<hr class="my-4" />
									<map-visualizer :geo-json-urls="project.map.geoJsonUrls" :map-data="livestock.maps"></map-visualizer>
									<div class="d-flex mb-2">
										<div><h3 class="my-0">Potential Loads by Subbasin</h3></div>
										<div class="ml-auto text-right">
											<json-to-csv :json-data="livestock.table" :filename="`select-livestock-load-${toValidFileName(project.name)}`"></json-to-csv>
										</div>
									</div>
									<b-table responsive striped hover :items="livestock.table" :fields="tables.livestock.fields"></b-table>
								</div>
							</b-tab>
							<b-tab title="Wildlife" @click="page.wildlifeMapPanel.key++">
								<p>
									<b-button type="button" variant="secondary" @click="page.defaultWildlifeRates.show = true">Set Land Cover</b-button>
								</p>

								<b-modal v-model="page.defaultWildlifeRates.show" title="Set Land Cover" size="xl">
									<error-list :errors="page.defaultWildlifeRates.errors"></error-list>

									<b-row>
										<b-col md>
											<div class="checklist-header">
												Select Land Cover Types
											</div>
											<div class="checklist">
												<b-form-checkbox-group v-model="selectedWildlifeLandCover" stacked>
													<div class="item" v-for="(o, i) in landCoversNoOpenWater" :key="i">
														<b-form-checkbox :value="o.value">{{o.text}}</b-form-checkbox>
													</div>
												</b-form-checkbox-group>
											</div>
										</b-col>
										<b-col md>
											<div class="checklist-header">
												Apply Rates to Selected Animals
											</div>
											<div class="checklist">
												<b-form-checkbox-group v-model="page.defaultWildlifeRates.animals" stacked>
													<div class="item"><b-form-checkbox value="Feral Hogs">Feral Hogs</b-form-checkbox></div>
													<div class="item"><b-form-checkbox value="Deer">Deer</b-form-checkbox></div>
												</b-form-checkbox-group>
											</div>

											<b-form-group label="OPTIONAL: Set Uniform Stocking Rate" class="mt-4"
														  description="Set your own stocking rate for the checked landuse and animals, applied to all subbasins" label-class="font-weight-bold">
												<b-form-input v-model.number="page.defaultWildlifeRates.override" type="number" required step="any"></b-form-input>
											</b-form-group>
										</b-col>
									</b-row>

									<div slot="modal-footer">
										<save-button type="button" :saving="page.defaultWildlifeRates.saving" @click.native="getDefaultWildlifeRates" text="Get Rates &amp; Apply to Form" variant="success" :disabled="page.defaultWildlifeRates.saving" />
										<b-button type="button" variant="secondary" @click="page.defaultWildlifeRates.show = false" class="ml-1">Cancel</b-button>
									</div>
								</b-modal>

								<b-table-simple small responsive bordered class="mb-3 table-valign-m">
									<b-thead>
										<b-tr>
											<b-th variant="light" colspan="4"></b-th>
											<b-th variant="light" colspan="15">Stocking Rates (acres/head)</b-th>
										</b-tr>
										<b-tr>
											<b-th></b-th>
											<b-th variant="light" sticky-column>Animal</b-th>
											<b-th>Conv. Factor</b-th>
											<b-th>Production Rate</b-th>
											<b-th v-for="(c, k) in project.map.connections" :key="k" :class="c.fromID === wildlifeSelectedId && page.wildlifeMapPanel.show ? 'bg-lighter-blue' : ''" :id="`wildlifeSubHeader${c.fromID}`">
												{{c.fromName}}
											</b-th>
										</b-tr>
									</b-thead>
									<b-tbody>
										<b-tr v-for="(item, i) in project.inputs.wildlifeFecalNumbers" :key="i">
											<b-th><b-button variant="light" size="sm" title="Reset stocking rates to 0" @click="setWildlifeRate(item.animal, 0)">Clear</b-button></b-th>
											<b-th variant="light" sticky-column>{{item.animal}}</b-th>
											<b-td>
												<b-form-input type="number" step="any" class="number-field"
															  v-model.number.trim="$v.project.inputs.wildlifeFecalNumbers.$each[i].fecalToEcoliRatio.$model"
															  :state="getValidState($v.project.inputs.wildlifeFecalNumbers.$each[i].fecalToEcoliRatio)"></b-form-input>
											</b-td>
											<b-td>
												<b-form-input type="number" step="any" class="big-number-field"
															  v-model.number.trim="$v.project.inputs.wildlifeFecalNumbers.$each[i].fecalProductionRate.$model"
															  :state="getValidState($v.project.inputs.wildlifeFecalNumbers.$each[i].fecalProductionRate)"></b-form-input>
											</b-td>
											<b-td v-for="(c, j) in project.map.connections" :key="j">
												<b-form-input type="number" step="any" class="number-field"
															  @focus="setWildlifeMapSubbasin(c.fromID, c.fromName)"
															  v-model.number.trim="$v.project.inputs.subWildlifeStockingRates.$each[getWildlifeIndex(c.fromID, item.animal)].stockingRate.$model"
															  :state="getValidState($v.project.inputs.subWildlifeStockingRates.$each[getWildlifeIndex(c.fromID, item.animal)].stockingRate)"></b-form-input>
											</b-td>
										</b-tr>
									</b-tbody>
								</b-table-simple>

								<div class="mb-3">
									<b-collapse v-model="page.wildlifeMapPanel.show" class="mb-1">
										<project-map :key="page.wildlifeMapPanel.key" :project-map="project.map" height="300px"
													 :show-acres="page.showAcres"
													 highlight-selected
													 :selected-subbasins="page.wildlifeMapPanel.selected"
													 @subbasin-click="wildlifeMapClick"></project-map>
									</b-collapse>
									<b-button variant="primary" @click="toggleWildlifeMap">Toggle Map</b-button>
								</div>

								<p class="mb-0">
									In the table above:
								</p>
								<ul>
									<li>Conv. Factor = Fecal coliform to E. coli conversion factor</li>
									<li>Production Rate = Fecal coliform production rate (cfu/head/day)</li>
								</ul>

								<page-loading :loading="page.saving"></page-loading>
								<div v-if="!page.saving && wildlife.table.length > 0">
									<hr class="my-4" />
									<map-visualizer :geo-json-urls="project.map.geoJsonUrls" :map-data="wildlife.maps"></map-visualizer>
									<div class="d-flex mb-2">
										<div><h3 class="my-0">Potential Loads by Subbasin</h3></div>
										<div class="ml-auto text-right">
											<json-to-csv :json-data="wildlife.table" :filename="`select-wildlife-load-${toValidFileName(project.name)}`"></json-to-csv>
										</div>
									</div>
									<b-table responsive striped hover :items="wildlife.table" :fields="tables.wildlife.fields"></b-table>
								</div>
							</b-tab>
							<b-tab title="OSSF">
								<b-row>
									<b-col sm>
										<b-form-group label="Select source for OSSF numbers" :invalid-feedback="requiredFeedback($v.project.inputs.ossfField)">
											<b-form-select v-model.trim="$v.project.inputs.ossfField.$model" :options="options.ossfFields" required :state="getValidState($v.project.inputs.ossfField)">
												<template v-slot:first>
													<b-form-select-option :value="null" disabled>-- Please select an option --</b-form-select-option>
												</template>
											</b-form-select>
										</b-form-group>
										<b-form-group label="Waste water (gal/person/day)" :invalid-feedback="requiredFeedback($v.project.inputs.ossfWasteWater)">
											<b-form-input v-model.number.trim="$v.project.inputs.ossfWasteWater.$model" type="number" required step="any" :state="getValidState($v.project.inputs.ossfWasteWater)"></b-form-input>
										</b-form-group>
										<b-form-group v-if="!project.inputs.useSoilClassFailureRate" label="Failure rate (fraction)" :invalid-feedback="requiredFeedback($v.project.inputs.ossfFailureRate)">
											<b-form-input v-model.number.trim="$v.project.inputs.ossfFailureRate.$model" type="number" required step="any" min="0" max="1" :state="getValidState($v.project.inputs.ossfFailureRate)"></b-form-input>
										</b-form-group>
										<div>
											<b-form-checkbox v-model.trim="$v.project.inputs.useSoilClassFailureRate.$model">
												Use soil drain field class for failure rate
											</b-form-checkbox>
										</div>
									</b-col>
									<b-col sm>
										<b-form-group label="Fecal coliform to E. coli conversion factor" :invalid-feedback="requiredFeedback($v.project.inputs.ossfFecalToEcoliRatio)">
											<b-form-input v-model.number.trim="$v.project.inputs.ossfFecalToEcoliRatio.$model" type="number" required step="any" :state="getValidState($v.project.inputs.ossfFecalToEcoliRatio)"></b-form-input>
										</b-form-group>
										<b-form-group label="Fecal coliform concentration (#cfu/100ml)" :invalid-feedback="requiredFeedback($v.project.inputs.ossfFecalProductionRate)">
											<b-form-input v-model.number.trim="$v.project.inputs.ossfFecalProductionRate.$model" type="number" required step="any" :state="getValidState($v.project.inputs.ossfFecalProductionRate)"></b-form-input>
										</b-form-group>
									</b-col>
								</b-row>

								<page-loading :loading="page.saving"></page-loading>
								<div v-if="!page.saving && ossf.table.length > 0">
									<hr class="my-4" />
									<map-visualizer :geo-json-urls="project.map.geoJsonUrls" :map-data="ossf.maps"></map-visualizer>
									<div class="d-flex mb-2">
										<div><h3 class="my-0">Potential Loads by Subbasin</h3></div>
										<div class="ml-auto text-right">
											<json-to-csv :json-data="ossf.table" :filename="`select-ossf-load-${toValidFileName(project.name)}`"></json-to-csv>
										</div>
									</div>
									<b-table responsive striped hover :items="ossf.table" :fields="tables.ossf.fields"></b-table>
								</div>
							</b-tab>
							<b-tab title="WWTF/WWTP">
								<b-button @click="page.wwtfInputsVisible = !page.wwtfInputsVisible" v-if="wwtf.table.length > 0">
									{{page.wwtfInputsVisible ? 'Hide' : 'Show'}} WWTF table
								</b-button>
								<b-collapse v-model="page.wwtfInputsVisible" class="mt-2">
									<p>
										Scroll the table below and click the edit button next to a row to modify the daily max. per sample or discharge values,
										or to remove the station from the load calculations.
									</p>
									<div class="text-right mb-2">
										<json-to-csv size="sm" :json-data="options.wwtfs" :filename="`select-wwtfs-${toValidFileName(project.name)}`"></json-to-csv>
									</div>
									<b-table responsive small bordered hover sticky-header head-variant="light" style="max-height:400px"
											 :items="options.wwtfs"
											 :fields="tables.wwtfInputs.fields"
											 :tbody-tr-class="wwtfInputsRowClass">
										<template v-slot:cell(edit)="data">
											<button type="button" class="plain text-primary" title="Edit" @click="editWwtf(data.item)">
												<font-awesome-icon :icon="['fas', 'edit']" />
											</button>
										</template>
										<template v-slot:cell(eColiDailyAverage)="data">
											<span :class="data.item.eColiDailyAverage === data.item.origEColiDailyAverage ? null : 'font-weight-bold'">
												{{data.item.eColiDailyAverage | number(2)}}
											</span>
										</template>
										<template v-slot:cell(flowLimit)="data">
											<span :class="data.item.flowLimit === data.item.origFlowLimit ? null : 'font-weight-bold'">
												{{data.item.flowLimit | number(2)}}
											</span>
										</template>
									</b-table>
								</b-collapse>

								<b-modal v-model="page.wwtfEditor.show" title="Edit WWTF">
									<error-list :errors="page.wwtfEditor.errors"></error-list>

									<div v-if="page.wwtfEditor.item === null">
										Please select a row from the table.
									</div>
									<div v-else>
										<b-alert :show="project.inputs.removedWwtfs.includes(page.wwtfEditor.item.id)" variant="danger">
											This WWTF has been removed from the load calculations. Click the 'Add WWTF' button below to include it.
										</b-alert>
										<p>
											Modify the <em>E. coli</em> daily maximum per sample and/or discharge values for
											<strong>{{page.wwtfEditor.item.facilityID}} - {{page.wwtfEditor.item.facilityName}}</strong>
											located in subbasin {{page.wwtfEditor.item.subbasin}}.
										</p>

										<b-form-group label="E. coli daily maximum per sample (cfu/100 ml)"
													  :description="page.wwtfEditor.item.eColiDailyAverage === page.wwtfEditor.item.origEColiDailyAverage ? null : `Original value was ${numberFormat(page.wwtfEditor.item.origEColiDailyAverage)}`"
													  :invalid-feedback="requiredFeedback($v.page.wwtfEditor.item.eColiDailyAverage)">
											<b-form-input v-model.number.trim="$v.page.wwtfEditor.item.eColiDailyAverage.$model" type="number" required step="any" :state="getValidState($v.page.wwtfEditor.item.eColiDailyAverage)"></b-form-input>
										</b-form-group>

										<b-form-group label="Discharge (MGD)"
													  :description="page.wwtfEditor.item.flowLimit === page.wwtfEditor.item.origFlowLimit ? null : `Original value was ${numberFormat(page.wwtfEditor.item.origFlowLimit)}`"
													  :invalid-feedback="requiredFeedback($v.page.wwtfEditor.item.flowLimit)">
											<b-form-input v-model.number.trim="$v.page.wwtfEditor.item.flowLimit.$model" type="number" required step="any" :state="getValidState($v.page.wwtfEditor.item.flowLimit)"></b-form-input>
										</b-form-group>
									</div>

									<div slot="modal-footer">
										<b-button type="button" variant="success" @click="saveWwtf" class="ml-1">Confirm Changes</b-button>
										<span v-if="page.wwtfEditor.item !== null">
											<b-button v-if="!project.inputs.removedWwtfs.includes(page.wwtfEditor.item.id)" type="button" variant="danger" @click="removeWwtf" class="ml-1">Remove WWTF</b-button>
											<b-button v-else type="button" variant="info" @click="addWwtf" class="ml-1">Add WWTF</b-button>
										</span>
										<b-button type="button" variant="secondary" @click="page.wwtfEditor.show = false" class="ml-1">Cancel</b-button>
									</div>
								</b-modal>

								<page-loading :loading="page.saving"></page-loading>
								<div v-if="!page.saving && wwtf.table.length > 0">
									<hr class="my-4" />
									<map-visualizer :geo-json-urls="project.map.geoJsonUrls" :map-data="wwtf.maps"></map-visualizer>
									<div class="d-flex mb-2">
										<div><h3 class="my-0">Potential Loads by Subbasin</h3></div>
										<div class="ml-auto text-right">
											<json-to-csv :json-data="wwtf.table" :filename="`select-wwtf-load-${toValidFileName(project.name)}`"></json-to-csv>
										</div>
									</div>
									<b-table responsive striped hover :items="wwtf.table" :fields="tables.wwtf.fields"></b-table>
								</div>
							</b-tab>
							<b-tab title="Potential Total Load">
								<p v-if="totals.table.length == 0" class="lead text-center">
									Use the 'Calculate Load' button at the bottom of the screen to calculate the total load in your watershed based on your inputs in the previous tabs.
								</p>

								<page-loading :loading="page.saving"></page-loading>
								<div v-if="!page.saving && totals.table.length > 0">
									<b-row class="mt-5">
										<b-col>
											<map-visualizer :num-cols="12" option-button-below :geo-json-urls="project.map.geoJsonUrls" :map-data="totals.maps"></map-visualizer>
										</b-col>
										<b-col>
											<highcharts :options="charts.loadSource"></highcharts>
										</b-col>
									</b-row>

									<div>
										<highcharts :options="charts.loadBoxPlot"></highcharts>
									</div>

									<hr class="my-4" />

									<div class="d-flex mb-2">
										<div><h3 class="my-0">Potential Loads by Subbasin</h3></div>
										<div class="ml-auto text-right">
											<a :href="`/exports/totalload/${projectId}?access_token=${localStorageToken}`" class="btn btn-info">
												Download CSV
											</a>
										</div>
									</div>

									<b-table responsive striped hover small :items="totals.table" :fields="tables.totals.fields"></b-table>
								</div>
							</b-tab>

							<b-tab title="USGS Streams" v-if="false">
								<div>
									<save-button type="button" @click.native="getStreamStations" variant="info" :saving="page.saving" text="Get stations"></save-button>
								</div>
								<div v-if="usgs.stations !== null" class="my-4">
									<project-map :project-map="project.map" height="350px" show-layer-control :show-acres="page.showAcres" :usgs-stations="usgs.stations"></project-map>
								</div>
							</b-tab>
						</b-tabs>
					</div>

					<fixed-action-bar :cols-lg="12" :offset-lg="0" :cols="12" :offset="0" fixed-width>
						<b-button variant="light" @click="nextTab(-1)" class="border mr-2" :disabled="page.tabIndex == 0" title="Previous tab"><font-awesome-icon icon="chevron-left" /></b-button>
						<b-button variant="light" @click="nextTab(1)" class="border mr-2" :disabled="page.tabIndex == page.numTabs - 1" title="Next tab"><font-awesome-icon icon="chevron-right" /></b-button>

						<b-button v-if="totals.table.length > 0 && page.tabIndex === 6" type="button" :to="`/projects/print/${projectId}`" target="_blank" variant="info" class="mr-2">Print Report</b-button>
						<save-button v-if="page.calculateLoadTabs.includes(page.tabIndex)" text="Calculate Potential Load" :saving="page.saving" class="mr-2"></save-button>
						<back-button class="btn btn-secondary mr-2" />
						<b-button type="button" @click="page.copy.show = true" :disabled="page.saving" variant="warning" class="ml-auto mr-2">Copy</b-button>
						<b-button variant="danger" @click="page.delete.show = true">Delete</b-button>
					</fixed-action-bar>
				</b-form>

				<b-modal v-model="page.copy.show" title="Copy project">
					<error-list :errors="page.copy.errors"></error-list>

					<p>
						Click the button below to copy this project.
						When copying is complete, you'll be redirected to the page for the new project.
					</p>

					<div slot="modal-footer">
						<save-button type="button" :saving="page.copy.saving" @click.native="copy" text="Copy Project" variant="success" :disabled="page.copy.saving" />
						<b-button type="button" variant="secondary" @click="page.copy.show = false" class="ml-1">Cancel</b-button>
					</div>
				</b-modal>

				<b-modal v-model="page.delete.show" size="md" title="Confirm delete" no-close-on-backdrop no-close-on-esc hide-header-close>
					<error-list :errors="page.delete.errors"></error-list>

					<p>
						Are you sure you want to delete this project?
						This action is permanent and cannot be undone.
					</p>

					<div slot="modal-footer">
						<save-button type="button" :saving="page.delete.saving" @click.native="confirmDelete" text="Delete" variant="danger" />
						<b-button type="button" variant="secondary" @click="page.delete.show = false" class="ml-1">Cancel</b-button>
					</div>
				</b-modal>

				<b-modal v-model="page.edit.show" title="Edit project name">
					<error-list :errors="page.edit.errors"></error-list>

					<b-form-group label="Provide a new name for the project">
						<b-form-input v-model="page.edit.name" type="text" required></b-form-input>
					</b-form-group>

					<div slot="modal-footer">
						<save-button type="button" :saving="page.edit.saving" @click.native="editProject" />
						<b-button type="button" variant="secondary" @click="page.edit.show = false" class="ml-1">Cancel</b-button>
					</div>
				</b-modal>
			</auth-container>
		</b-container>
		<router-view></router-view>
	</div>
</template>

<script>
	import ProjectMap from '@/components/ProjectMap';
	import MapVisualizer from '@/components/MapVisualizer';
	import JsonToCsv from '@/components/helpers/JsonToCsv';
	import moment from 'moment';
	import { required, decimal, between } from 'vuelidate/lib/validators';

	export default {
		name: 'ProjectView',
		components: {
			ProjectMap, MapVisualizer, JsonToCsv
		},
		data() {
			return {
				projectId: this.$route.params.id,
				page: {
					errors: [],
					loading: false,
					showLogin: false,
					saveErrors: [],
					saving: false,
					delete: {
						show: false,
						errors: [],
						saving: false
					},
					copy: {
						show: false,
						errors: [],
						saving: false
					},
					edit: {
						show: false,
						errors: [],
						saving: false,
						name: ''
					},
					defaultLivestockRates: {
						show: false,
						errors: [],
						saving: false,
						animals: ['Cattle', 'Sheep', 'Goats', 'Horses'],
						override: null,
					},
					defaultWildlifeRates: {
						show: false,
						errors: [],
						saving: false,
						animals: ['Feral Hogs', 'Deer'],
						override: null
					},
					showAcres: true,
					tabIndex: 0,
					numTabs: 7,
					calculateLoadTabs: [1, 2, 3, 4, 5, 6],
					wwtfInputsVisible: true,
					wwtfEditor: {
						item: null,
						show: false,
						errors: [],
						saving: false
					},
					livestockMapPanel: {
						show: false,
						key: 1,
						selected: []
					},
					wildlifeMapPanel: {
						show: false,
						key: 1,
						selected: []
					},
					watershedMapKey: 1
				},
				project: {
					map: {},
					inputs: {
						dogDensity: 0,
						catDensity: 0,
						petsFecalToEcoliRatio: 0,
						petsFecalProductionRate: 0,
						subLivestockStockingRates: [],
						subWildlifeStockingRates: [],
						livestockFecalNumbers: [],
						wildlifeFecalNumbers: [],
						useSoilClassFailureRate: false,
						ossfFailureRate: 0,
						ossfWasteWater: 0,
						ossfFecalToEcoliRatio: 0,
						ossfFecalProductionRate: 0,
						ossfField: null,
						removedWwtfs: [],
						modifiedWwtfs: []
					}
				},
				counties: [],
				chartData: {},
				options: {
					landCovers: [],
					livestockAnimals: [],
					wildlifeAnimals: []
				},
				charts: {
					percentWatershedInCounty: {},
					percentCountyInWatershed: {},
					loadSource: {},
					loadBoxPlot: {}
				},
				pets: {
					table: [],
					maps: []
				},
				livestock: {
					table: [],
					maps: []
				},
				wildlife: {
					table: [],
					maps: []
				},
				ossf: {
					table: [],
					maps: []
				},
				wwtf: {
					table: [],
					maps: []
				},
				totals: {
					table: [],
					maps: [],
					charts: {
						loadSource: []
					}
				},
				selectedLivestockLandCover: ['Shrub', 'Grassland', 'Pasture'],
				selectedWildlifeLandCover: ['Shrub', 'Grassland', 'Pasture'],
				defaultLivestock: [],
				defaultWildlife: [],
				tables: {
					counties: {
						fields: [
							{ key: 'county', sortable: true },
							{ key: 'countyArea', sortable: true, formatter: (value) => { return this.toAcresFormatter(value, this.page.showAcres) }, class: 'text-right' },
							{ key: 'areaInWatershed', sortable: true, formatter: (value) => { return this.toAcresFormatter(value, this.page.showAcres) }, class: 'text-right' },
							{ key: 'percentWatershedInCounty', sortable: true, formatter: (value) => { return this.numberFormat(value) + '%' }, class: 'text-right' },
							{ key: 'percentCountyInWatershed', sortable: true, formatter: (value) => { return this.numberFormat(value) + '%' }, class: 'text-right' }
						]
					},
					pets: {
						fields: [
							{ key: 'subbasin', sortable: true },
							{ key: 'dogLoad', sortable: true, label: 'Dog Load (billion cfu/day)', formatter: (value) => { return this.numberFormat(value) }, class: 'text-right' },
							{ key: 'catLoad', sortable: true, label: 'Cat Load (billion cfu/day)', formatter: (value) => { return this.numberFormat(value) }, class: 'text-right' },
							//{ key: 'population', sortable: true, formatter: (value) => { return this.numberFormat(value, 0) }, class: 'text-right' },
							{ key: 'housingUnits', sortable: true, formatter: (value) => { return this.numberFormat(value, 0) }, class: 'text-right' }
							//{ key: 'personsPerHousehold', sortable: true, label: 'Avg. Persons Per Household', formatter: (value) => { return this.numberFormat(value, 2) }, class: 'text-right' }
						]
					},
					livestock: {
						fields: [
							{ key: 'subbasin', sortable: true },
							{ key: 'cattleLoad', sortable: true, label: 'Cattle Load (billion cfu/day)', formatter: (value) => { return this.numberFormat(value) }, class: 'text-right' },
							{ key: 'sheepLoad', sortable: true, label: 'Sheep Load (billion cfu/day)', formatter: (value) => { return this.numberFormat(value) }, class: 'text-right' },
							{ key: 'goatLoad', sortable: true, label: 'Goat Load (billion cfu/day)', formatter: (value) => { return this.numberFormat(value) }, class: 'text-right' },
							{ key: 'horseLoad', sortable: true, label: 'Horse Load (billion cfu/day)', formatter: (value) => { return this.numberFormat(value) }, class: 'text-right' }
						]
					},
					wildlife: {
						fields: [
							{ key: 'subbasin', sortable: true },
							{ key: 'deerLoad', sortable: true, label: 'Deer Load (billion cfu/day)', formatter: (value) => { return this.numberFormat(value) }, class: 'text-right' },
							{ key: 'feralHogLoad', sortable: true, label: 'Feral Hog Load (billion cfu/day)', formatter: (value) => { return this.numberFormat(value) }, class: 'text-right' }
						]
					},
					ossf: {
						fields: [
							{ key: 'subbasin', sortable: true },
							{ key: 'ossf', sortable: true, label: 'OSSF#', formatter: (value) => { return this.numberFormat(value, 0) }, class: 'text-right' },
							{ key: 'failureRate', sortable: true, formatter: (value) => { return this.numberFormat(value, 2) }, class: 'text-right' },
							{ key: 'personsPerHousehold', sortable: true, label: 'Avg. Persons Per Household', formatter: (value) => { return this.numberFormat(value, 2) }, class: 'text-right' },
							{ key: 'load', sortable: true, label: 'Load (billion cfu/day)', formatter: (value) => { return this.numberFormat(value) }, class: 'text-right' }
						]
					},
					wwtf: {
						fields: [
							{ key: 'subbasin', sortable: true },
							{ key: 'load', sortable: true, label: 'Load (billion cfu/day)', formatter: (value) => { return this.numberFormat(value) }, class: 'text-right' }
						]
					},
					totals: {
						fields: [
							{ key: 'subbasin', sortable: true },
							{ key: 'totalLoad', sortable: true, label: 'Total Load', formatter: (value) => { return this.numberFormat(value) }, class: 'text-right' },
							{ key: 'dogLoad', sortable: true, label: 'Dog Load', formatter: (value) => { return this.numberFormat(value) }, class: 'text-right' },
							{ key: 'catLoad', sortable: true, label: 'Cat Load', formatter: (value) => { return this.numberFormat(value) }, class: 'text-right' },
							{ key: 'cattleLoad', sortable: true, label: 'Cattle Load', formatter: (value) => { return this.numberFormat(value) }, class: 'text-right' },
							{ key: 'sheepLoad', sortable: true, label: 'Sheep Load', formatter: (value) => { return this.numberFormat(value) }, class: 'text-right' },
							{ key: 'goatLoad', sortable: true, label: 'Goat Load', formatter: (value) => { return this.numberFormat(value) }, class: 'text-right' },
							{ key: 'horseLoad', sortable: true, label: 'Horse Load', formatter: (value) => { return this.numberFormat(value) }, class: 'text-right' },
							{ key: 'deerLoad', sortable: true, label: 'Deer Load', formatter: (value) => { return this.numberFormat(value) }, class: 'text-right' },
							{ key: 'feralHogLoad', sortable: true, label: 'Feral Hog Load', formatter: (value) => { return this.numberFormat(value) }, class: 'text-right' },
							{ key: 'ossfLoad', sortable: true, label: 'OSSF Load', formatter: (value) => { return this.numberFormat(value) }, class: 'text-right' },
							{ key: 'wwtfLoad', sortable: true, label: 'WWTF Load', formatter: (value) => { return this.numberFormat(value) }, class: 'text-right' }
						]
					},
					countyLivestock: {
						fields: [
							{ key: 'county', sortable: true },
							{ key: 'totalCountyArea', sortable: true, formatter: (value) => { return this.toAcresFormatter(value, this.page.showAcres) }, class: 'text-right' },
							{ key: 'suitableAreaInWatershed', sortable: true, label: 'Suitable Area', formatter: (value) => { return this.toAcresFormatter(value, this.page.showAcres) }, class: 'text-right' },
							{ key: 'cattle', sortable: true, formatter: (value) => { return this.numberFormat(value, 0) }, class: 'text-right' },
							{ key: 'sheep', sortable: true, formatter: (value) => { return this.numberFormat(value, 0) }, class: 'text-right' },
							{ key: 'goats', sortable: true, formatter: (value) => { return this.numberFormat(value, 0) }, class: 'text-right' },
							{ key: 'horses', sortable: true, formatter: (value) => { return this.numberFormat(value, 0) }, class: 'text-right' }
						]
					},
					subWildlife: {
						fields: [
							{ key: 'subbasin', sortable: true },
							{ key: 'ecoRegion', sortable: true },
							{ key: 'suitableDeerArea', sortable: true, label: 'Suitable Area for Deer', formatter: (value) => { return this.toAcresFormatter(value, this.page.showAcres) }, class: 'text-right' },
							{ key: 'deerDensity', sortable: true, label: 'Deer Rate (acre/AU)', formatter: (value) => { return this.numberFormat(value) }, class: 'text-right' },
							{ key: 'deer', sortable: true, label: '# Deer', formatter: (value) => { return this.numberFormat(value, 0) }, class: 'text-right' },
							{ key: 'suitableFeralHogsArea', sortable: true, label: 'Suitable Area for Feral Hogs', formatter: (value) => { return this.toAcresFormatter(value, this.page.showAcres) }, class: 'text-right' },
							{ key: 'feralHogsDensity', sortable: true, label: 'Feral Hog Rate (acre/AU)', formatter: (value) => { return this.numberFormat(value) }, class: 'text-right' },
							{ key: 'feralHogs', sortable: true, label: '# Feral Hogs', formatter: (value) => { return this.numberFormat(value, 0) }, class: 'text-right' }
						]
					},
					wwtfInputs: {
						fields: [
							{ key: 'edit', label: '' },
							{ key: 'facilityID', sortable: true, stickyColumn: false },
							{ key: 'subbasin', sortable: true },
							{ key: 'eColiDailyAverage', sortable: true, label: 'Daily Max. / Sample', formatter: (value) => { return this.numberFormat(value) }, class: 'text-right' },
							{ key: 'flowLimit', sortable: true, label: 'Discharge (MGD)', formatter: (value) => { return this.numberFormat(value) }, class: 'text-right' },
							{ key: 'facilityName', sortable: true },
							{ key: 'location', sortable: true },
							{ key: 'city', sortable: true },
							{ key: 'latitude', sortable: true, formatter: (value) => { return this.numberFormat(value) }, class: 'text-right' },
							{ key: 'longitude', sortable: true, formatter: (value) => { return this.numberFormat(value) }, class: 'text-right' }
						]
					}
				},
				usgs: {
					stations: null
				}
			}
		},
		validations: {
			page: {
				wwtfEditor: {
					item: {
						id: { required },
						flowLimit: { required, decimal },
						eColiDailyAverage: { required, decimal }
					}
				}
			},
			project: {
				inputs: {
					dogDensity: { required, decimal },
					catDensity: { required, decimal },
					petsFecalToEcoliRatio: { required, decimal },
					petsFecalProductionRate: { required, decimal },
					subLivestockStockingRates: {
						$each: {
							animal: { required },
							subbasinId: { required },
							stockingRate: { required, decimal },
							suitableArea: { required, decimal }
						}
					},
					subWildlifeStockingRates: {
						$each: {
							animal: { required },
							subbasinId: { required },
							stockingRate: { required, decimal },
							suitableArea: { required, decimal }
						}
					},
					livestockFecalNumbers: {
						$each: {
							animal: { required },
							fecalToEcoliRatio: { required, decimal },
							fecalProductionRate: { required, decimal }
						}
					},
					wildlifeFecalNumbers: {
						$each: {
							animal: { required },
							fecalToEcoliRatio: { required, decimal },
							fecalProductionRate: { required, decimal }
						}
					},
					useSoilClassFailureRate: {},
					ossfFailureRate: { required, decimal, between: between(0, 1) },
					ossfWasteWater: { required, decimal },
					ossfFecalToEcoliRatio: { required, decimal },
					ossfFecalProductionRate: { required, decimal },
					ossfField: { required },
					removedWwtfs: {},
					modifiedWwtfs: {}
				}
			}
		},
		async created() {
			await this.get();
		},
		watch: {
			'$route': 'get'
		},
		methods: {
			async get() {
				this.page.errors = [];
				this.page.loading = true;
				this.projectId = this.$route.params.id;

				try {
					const response = await this.$http.get(`projects/${this.projectId}`, this.getTokenHeader());
					this.log(response.data);
					this.project = response.data.project;
					this.counties = response.data.counties;
					this.chartData = response.data.chartData;
					this.options = response.data.options;
					this.setCharts();
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			},
			async getStreamStations() {
				this.page.saveErrors = [];
				this.page.saving = true;
				this.usgs.stations = null;

				let url = 'https://www.waterqualitydata.us/data/Station/search?mimeType=geojson';

				/*if (this.project.map.huc8s.length > 0) {
					let hucs = '';
					for (let h of this.project.map.huc8s) {
						hucs += h + ';';
					}
					url += `&huc=${hucs}`;
				} else {*/
					//w, s, e, n
					let bounds = this.project.map.watershedBounds;
					let w = bounds[1][1];
					let s = bounds[1][0];
					let e = bounds[0][1];
					let n = bounds[0][0];
					let bBox = `${w},${s},${e},${n}`;
					url += `&bBox=${bBox}`;
					this.log(bBox);
				//}

				try {
					const response = await this.$httpRoot.get(url);
					this.log(response.data);
					this.usgs.stations = response.data;
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.saveErrors = this.logError(error);
				}

				this.page.saving = false;
			},
			async save() {
				if (this.page.tabIndex === 1) await this.calculatePets();
				if (this.page.tabIndex === 2) await this.calculateLivestock();
				if (this.page.tabIndex === 3) await this.calculateWildlife();
				if (this.page.tabIndex === 4) await this.calculateOssf();
				if (this.page.tabIndex === 5) await this.calculateWwtf();
				if (this.page.tabIndex === 6) await this.calculateTotal();
			},
			async calculatePets() {
				this.page.saveErrors = [];
				this.page.saving = true;

				try {
					const response = await this.$http.post(`projects/pets/${this.projectId}`, this.project.inputs, this.getTokenHeader());
					this.log(response.data);
					this.pets = response.data;
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.saveErrors = this.logError(error);
				}

				this.page.saving = false;
			},
			async calculateLivestock() {
				this.page.saveErrors = [];
				this.page.saving = true;

				try {
					const response = await this.$http.post(`projects/livestock/${this.projectId}`, this.project.inputs, this.getTokenHeader());
					this.log(response.data);
					this.livestock = response.data;
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.saveErrors = this.logError(error);
				}

				this.page.saving = false;
			},
			async calculateWildlife() {
				this.page.saveErrors = [];
				this.page.saving = true;

				try {
					const response = await this.$http.post(`projects/wildlife/${this.projectId}`, this.project.inputs, this.getTokenHeader());
					this.log(response.data);
					this.wildlife = response.data;
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.saveErrors = this.logError(error);
				}

				this.page.saving = false;
			},
			async calculateOssf() {
				this.page.saveErrors = [];
				this.page.saving = true;

				try {
					const response = await this.$http.post(`projects/ossf/${this.projectId}`, this.project.inputs, this.getTokenHeader());
					this.log(response.data);
					this.ossf = response.data;
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.saveErrors = this.logError(error);
				}

				this.page.saving = false;
			},
			async calculateWwtf() {
				this.page.saveErrors = [];
				this.page.saving = true;

				try {
					const response = await this.$http.post(`projects/wwtf/${this.projectId}`, this.project.inputs, this.getTokenHeader());
					this.log(response.data);
					this.wwtf = response.data;
					this.page.wwtfInputsVisible = false;
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.saveErrors = this.logError(error);
				}

				this.page.saving = false;
			},
			async calculateTotal() {
				this.page.saveErrors = [];
				this.page.saving = true;

				try {
					const response = await this.$http.post(`projects/total/${this.projectId}`, this.project.inputs, this.getTokenHeader());
					this.log(response.data);
					this.totals = response.data;
					this.charts.loadSource = this.getPieChart('Contribution to Potential Total Load', this.totals.charts.loadSource, 'Contribution');
					this.charts.loadBoxPlot = this.getBoxPlot(this.totals.charts.loadBoxPlot);
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.saveErrors = this.logError(error);
				}

				this.page.saving = false;
			},
			async getDefaultLivestockRates() {
				this.page.defaultLivestockRates.errors = [];
				this.page.defaultLivestockRates.saving = true;

				if (this.selectedLivestockLandCover.length < 1) {
					this.page.defaultLivestockRates.errors.push('Please select at least one land cover.');
				} else {
					try {
						const response = await this.$http.post(`projects/defaultStockingRate/${this.projectId}`, this.selectedLivestockLandCover, this.getTokenHeader());
						this.log(response.data);
						this.defaultLivestock = response.data;
						this.copyDefaultLivestockStockingRates();
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.defaultLivestockRates.errors = this.logError(error);
					}
				}

				this.page.defaultLivestockRates.saving = false;
			},
			copyDefaultLivestockStockingRates() {
				let animals = this.page.defaultLivestockRates.animals;
				let rates = this.project.inputs.subLivestockStockingRates.filter(function (el) { return !animals.includes(el.animal); });
				let hasOverride = this.page.defaultLivestockRates.override !== null;
				let override = this.page.defaultLivestockRates.override;

				for (let row of this.defaultLivestock) {
					if (animals.includes('Cattle')) {
						let sr = hasOverride ? override : row.cattle;
						rates.push({ animal: 'Cattle', stockingRate: sr, subbasinId: row.id, suitableArea: row.suitableArea });
					}
					if (animals.includes('Sheep')) {
						let sr = hasOverride ? override : row.sheep;
						rates.push({ animal: 'Sheep', stockingRate: sr, subbasinId: row.id, suitableArea: row.suitableArea });
					}
					if (animals.includes('Horses')) {
						let sr = hasOverride ? override : row.equine;
						rates.push({ animal: 'Horses', stockingRate: sr, subbasinId: row.id, suitableArea: row.suitableArea });
					}
					if (animals.includes('Goats')) {
						let sr = hasOverride ? override : row.goats;
						rates.push({ animal: 'Goats', stockingRate: sr, subbasinId: row.id, suitableArea: row.suitableArea });
					}
				}
				this.log(rates);
				this.log(this.project.inputs.subLivestockStockingRates);
				this.project.inputs.subLivestockStockingRates = rates;
				this.page.defaultLivestockRates.show = false;
				this.page.defaultLivestockRates.override = null;
			},
			async getDefaultWildlifeRates() {
				this.page.defaultWildlifeRates.errors = [];
				this.page.defaultWildlifeRates.saving = true;

				if (this.selectedWildlifeLandCover.length < 1) {
					this.page.defaultWildlifeRates.errors.push('Please select at least one land cover.');
				} else {
					try {
						const response = await this.$http.post(`projects/defaultWildlifeRate/${this.projectId}`, this.selectedWildlifeLandCover, this.getTokenHeader());
						this.log(response.data);
						this.defaultWildlife = response.data;
						this.copyDefaultWildlifeStockingRates();
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.defaulWildlifeRates.errors = this.logError(error);
					}
				}

				this.page.defaultWildlifeRates.saving = false;
			},
			copyDefaultWildlifeStockingRates() {
				let animals = this.page.defaultWildlifeRates.animals;
				let rates = this.project.inputs.subWildlifeStockingRates.filter(function (el) { return !animals.includes(el.animal); });
				let hasOverride = this.page.defaultWildlifeRates.override !== null;
				let override = this.page.defaultWildlifeRates.override;

				for (let row of this.defaultWildlife) {
					if (animals.includes('Feral Hogs')) {
						let sr = hasOverride ? override : row.feralHogsRate;
						rates.push({ animal: 'Feral Hogs', stockingRate: sr, subbasinId: row.id, suitableArea: row.suitableFeralHogsArea });
					}
					if (animals.includes('Deer')) {
						let sr = hasOverride ? override : row.deerRate;
						rates.push({ animal: 'Deer', stockingRate: sr, subbasinId: row.id, suitableArea: row.suitableDeerArea });
					}
				}
				this.log(rates);
				this.log(this.project.inputs.subWildlifeStockingRates);
				this.project.inputs.subWildlifeStockingRates = rates;
				this.page.defaultWildlifeRates.show = false;
				this.page.defaultWildlifeRates.override = null;
			},
			setCharts() {
				let chartData = this.chartData;
				this.charts.percentWatershedInCounty = this.getPieChart('Percent of Watershed in County', chartData.percentWatershedInCounty);
				this.charts.percentCountyInWatershed = this.getColumnChart('Percent of County in Watershed', chartData.percentCountyInWatershed, 'Percent of County in Watershed', 'Percent', true);
				this.charts.landCover = this.getColumnChart('Land Cover', chartData.landCover, 'Area', this.page.showAcres ? 'Area in acres' : 'Area in sq. km', false, this.page.showAcres);
				this.charts.landCoverPie = this.getPieChart('Land Cover Distribution', chartData.landCover);
			},
			updateCharts() {
				this.$nextTick(function () {
					this.setCharts();
				});
			},
			getPieChart(title, data, seriesLabel = 'Area') {
				return {
					plotOptions: { pie: { dataLabels: { enabled: false }, showInLegend: true } },
					title: { text: title },
					tooltip: { pointFormat: '{series.name}: <b>{point.percentage:,.1f}%</b>' },
					series: [{ data: data, name: seriesLabel, type: 'pie' }]
				};
			},
			getColumnChart(title, data, seriesName, yAxisTitle = 'Values', isPercentage = false, doConvert = false) {
				let yAxis = {
					title: { text: yAxisTitle }
				};
				if (isPercentage) {
					yAxis = {
						min: 0,
						max: 100,
						title: { text: yAxisTitle }
					};
				}

				let convertedData = [];
				if (doConvert) {
					for (let row of data) {
						convertedData.push({
							name: row.name,
							y: this.toAcres(row.y, doConvert)
						});
					}
				}

				return {
					chart: {
						type: 'column'
					},
					title: {
						text: title
					},
					xAxis: {
						type: 'category'
					},
					yAxis: yAxis,
					legend: {
						enabled: false
					},
					tooltip: {
						pointFormat: '<span style="color:{point.color}">●</span> {series.name}: <b>{point.y:,.1f}</b><br/>'
					},
					series: [{
						name: seriesName,
						colorByPoint: true,
						data: doConvert ? convertedData : data
					}]
				}
			},
			getBoxPlot(data) {
				this.log(data);
				return {
					chart: { type: 'boxplot' },
					title: { text: data.title },
					xAxis: {
						//categories: data.xAxisCategories,
						categories: ['Load Type'],
						title: { text: null }
					},
					yAxis: {
						title: {
							text: 'Potential Load'
						}
					},
					series: data.series,
					plotOptions: {
						series: { turboThreshold: 0 }
					},
					exporting: {
						csv: { dateFormat: '%Y-%m-%d' }
					}
				};
			},
			fromNow(d) {
				return moment(d).fromNow();
			},
			async copy() {
				this.page.copy.errors = [];
				this.page.copy.saving = true;

				try {
					const response = await this.$http.post(`projects/copy/${this.projectId}`, {}, this.getTokenHeader());
					this.page.copy.show = false;
					this.$router.push({ name: 'ProjectView', params: { id: response.data.toString() } }).catch(err => { this.log(err); });
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.copy.errors = this.logError(error);
				}

				this.page.copy.saving = false;
			},
			async confirmDelete() {
				this.page.delete.errors = [];
				this.page.delete.saving = true;

				try {
					const response = await this.$http.delete(`projects/${this.projectId}`, this.getTokenHeader());
					this.log(response.data);
					this.$router.push({ name: 'ProjectList' }).catch(err => { this.log(err); });
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.delete.errors = this.logError(error);
				}

				this.page.delete.saving = false;
			},
			async editProject() {
				this.page.edit.errors = [];
				this.page.edit.saving = true;

				if (this.isNullOrEmpty(this.page.edit.name)) {
					this.page.copy.errors.push('Please provide a name for the project.');
				} else {
					try {
						var data = {
							value: this.page.edit.name
						};
						await this.$http.put(`projects/name/${this.projectId}`, data, this.getTokenHeader());
						this.page.edit.show = false;
						await this.get(false);
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.edit.errors = this.logError(error);
					}
				}

				this.page.edit.saving = false;
			},
			scrollLeft(amount = 100) {
				let content = document.querySelector(".nav-tabs");
				content.scrollLeft -= amount;
			},
			scrollRight(amount = 100) {
				let content = document.querySelector(".nav-tabs");
				content.scrollLeft += amount;
			},
			nextTab(position) {
				var maxTabPos = this.page.numTabs - 1;
				this.page.tabIndex += position;

				if (this.page.tabIndex > maxTabPos)
					this.page.tabIndex = 0;
				if (this.page.tabIndex < 0)
					this.page.tabIndex = maxTabPos;

				let idx = this.page.tabIndex;
				if (idx === 0) this.page.watershedMapKey++;
				if (idx === 2) this.page.livestockMapPanel.key++;
				if (idx === 3) this.page.wildlifeMapPanel.key++;

				if (this.page.tabIndex > maxTabPos / 2)
					this.scrollRight(1000);
				else if (this.page.tabIndex < maxTabPos / 2)
					this.scrollLeft(1000);
			},
			rateToDensity(value) {
				if (value === 0) return 0;
				return 1000 / value;
			},
			wwtfInputsRowClass(item, type) {
				if (!item || type !== 'row') return;
				if (!item.included) return 'text-removed';
				if (item.eColiDailyAverage !== item.origEColiDailyAverage || item.flowLimit !== item.origFlowLimit) return 'table-info';
			},
			editWwtf(item) {
				this.page.wwtfEditor.item = item;
				this.page.wwtfEditor.show = true;
			},
			saveWwtf() {
				let item = this.page.wwtfEditor.item;
				let match = this.project.inputs.modifiedWwtfs.filter(function (el) { return el.id === item.id; });
				if (match.length > 0) {
					match.flowLimit = item.flowLimit;
					match.eColiDailyAverage = item.eColiDailyAverage;
				} else {
					this.project.inputs.modifiedWwtfs.push({
						id: item.id,
						flowLimit: item.flowLimit,
						eColiDailyAverage: item.eColiDailyAverage
					});
				}

				let tableRow = this.options.wwtfs.filter(function (el) { return el.id === item.id; })[0];
				tableRow.flowLimit = item.flowLimit;
				tableRow.eColiDailyAverage = item.eColiDailyAverage;

				this.page.wwtfEditor.item = null;
				this.page.wwtfEditor.show = false;
			},
			removeWwtf() {
				let item = this.page.wwtfEditor.item;
				if (!this.project.inputs.removedWwtfs.includes(item.id)) {
					this.project.inputs.removedWwtfs.push(item.id);
				}

				let tableRow = this.options.wwtfs.filter(function (el) { return el.id === item.id; })[0];
				tableRow.included = false;
				this.page.wwtfEditor.item = null;
				this.page.wwtfEditor.show = false;
			},
			addWwtf() {
				let item = this.page.wwtfEditor.item;
				this.project.inputs.removedWwtfs = this.project.inputs.removedWwtfs.filter(function (el) { return el !== item.id; });

				let tableRow = this.options.wwtfs.filter(function (el) { return el.id === item.id; })[0];
				tableRow.included = true;
				this.page.wwtfEditor.item = null;
				this.page.wwtfEditor.show = false;
			},
			getLivestockIndex(subId, animal) {
				return this.project.inputs.subLivestockStockingRates.findIndex(x => x.subbasinId === subId && x.animal === animal);
			},
			toggleLivestockMap() {
				this.page.livestockMapPanel.show = !this.page.livestockMapPanel.show;
				this.page.livestockMapPanel.key++;
			},
			livestockMapClick(selected) {
				if (selected.length > 0) {
					this.page.livestockMapPanel.selected = selected;
					//this.$scrollTo(`#livestockSubHeader${selected[0].id}`);
				}
			},
			setLivestockMapSubbasin(id, name) {
				this.page.livestockMapPanel.selected = [{ id: id, name: name }];
			},
			setLivestockRate(animal, value) {
				for (let obj of this.project.inputs.subLivestockStockingRates) {
					if (obj.animal === animal) {
						obj.stockingRate = value;
					}
				}
			},
			getWildlifeIndex(subId, animal) {
				return this.project.inputs.subWildlifeStockingRates.findIndex(x => x.subbasinId === subId && x.animal === animal);
			},
			toggleWildlifeMap() {
				this.page.wildlifeMapPanel.show = !this.page.wildlifeMapPanel.show;
				this.page.wildlifeMapPanel.key++;
			},
			wildlifeMapClick(selected) {
				if (selected.length > 0) {
					this.page.wildlifeMapPanel.selected = selected;
					//this.$scrollTo(`#wildlifeSubHeader${selected[0].id}`);
				}
			},
			setWildlifeMapSubbasin(id, name) {
				this.page.wildlifeMapPanel.selected = [{ id: id, name: name }];
			},
			setWildlifeRate(animal, value) {
				for (let obj of this.project.inputs.subWildlifeStockingRates) {
					if (obj.animal === animal) {
						obj.stockingRate = value;
					}
				}
			}
		},
		computed: {
			breadCrumbs() {
				var crumbs = [];
				for (var i = 0; i < this.$route.matched.length; i++) {
					var item = this.$route.matched[i];
					var name = item.meta.title ? item.meta.title : item.name;

					if (item.name === 'ProjectView') {
						name = this.project.name;
					}

					crumbs.push({
						text: name,
						to: { name: item.name }
					});
				}
				return crumbs;
			},
			livestockSelectedId() {
				let a = this.page.livestockMapPanel.selected;
				if (a.length > 0) return a[0].id;
				return null;
			},
			wildlifeSelectedId() {
				let a = this.page.wildlifeMapPanel.selected;
				if (a.length > 0) return a[0].id;
				return null;
			},
			countiesInAcres() {
				if (!this.page.showAcres) return this.counties;

				let newCounties = [];
				for (let c of this.counties) {
					newCounties.push({
						county: c.county,
						countyArea: this.toAcres(c.countyArea, true),
						areaInWatershed: this.toAcres(c.areaInWatershed, true),
						percentWatershedInCounty: c.percentWatershedInCounty,
						percentCountyInWatershed: c.percentCountyInWatershed
					});
				}
				return newCounties;
			},
			landCoversNoOpenWater() {
				return this.options.landCovers.filter(function (el) { return el.value !== 'OpenWater'; });
			}
		}
	}
</script>