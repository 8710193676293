<template>
	<div>
		<auth-container :page="page" require-admin>
			<h2 class="mb-4">Website configuration</h2>

			<b-alert variant="info" show>
				Please be cautious editing this page.
				In most situations, it is better to contact the developer to make any style/html changes regarding the
				text below.
			</b-alert>

			<error-list :errors="page.saveErrors"></error-list>
			<success-alert ref="savedAlert" text="Changes saved." />

			<b-form @submit.prevent="save">
				<b-form-group label="Site name/acronym" :invalid-feedback="requiredFeedback($v.data.siteName)">
					<b-form-input v-model="data.siteName" type="text" autofocus :state="getValidState($v.data.siteName)"></b-form-input>
				</b-form-group>

				<b-row>
					<b-col md>
						<b-form-group label="Long name">
							<b-form-input v-model="data.siteLongName" type="text"></b-form-input>
						</b-form-group>
					</b-col>
					<b-col md>
						<b-form-group label="Description">
							<b-form-input v-model="data.siteNameDescription" type="text"></b-form-input>
						</b-form-group>
					</b-col>
				</b-row>

				<b-form-group label="Home page main text">
					<tinymce-editor :api-key="tinymce.api" v-model="data.homePageText" :init="tinymce.init"></tinymce-editor>
				</b-form-group>

				<b-form-group label="Footer">
					<tinymce-editor :api-key="tinymce.api" v-model="data.footer" :init="tinymce.init"></tinymce-editor>
				</b-form-group>

				<b-form-group label="Help page">
					<tinymce-editor :api-key="tinymce.api" v-model="data.helpPageText" :init="tinymce.init"></tinymce-editor>
				</b-form-group>

				<fixed-action-bar :cols-lg="10" :offset-lg="2">
					<save-button :saving="page.saving" class="mr-2" />
					<back-button class="btn btn-secondary mr-2" />
				</fixed-action-bar>
			</b-form>
		</auth-container>
	</div>
</template>

<script>
	import { required } from 'vuelidate/lib/validators';
	import Editor from '@tinymce/tinymce-vue';

	export default {
		name: 'AdminWebsiteText',
		components: {
			'tinymce-editor': Editor
		},
		data() {
			return {
				page: {
					errors: [],
					loading: false,
					showLogin: false,
					saving: false,
					saveErrors: []
				},
				data: {
					appName: '',
					siteName: '',
					siteLongName: null,
					siteNameDescription: null,
					homePageText: null,
					footer: null,
					helpPageText: null
				},
				tinymce: {
					api: 'umc6fcf2lmc4vpzyrqb2jfcr2slzvqb94kk4ac6wl757gx5b',
					init: {
						height: 400,
						menubar: false,
						plugins: [
							'advlist autolink lists link image charmap print preview anchor',
							'searchreplace visualblocks code fullscreen',
							'insertdatetime media table paste code help wordcount'
						],
						toolbar: 'undo redo | formatselect | bold italic underline | link unlink | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | code',
					}
				}
			}
		},
		validations: {
			data: {
				siteName: { required }
			}
		},
		async created() {
			await this.get();
		},
		methods: {
			async get() {
				this.page.errors = [];
				this.page.loading = true;

				try {
					const response = await this.$http.get('admin/websitetext', this.getTokenHeader());
					this.log(response.data);
					this.data = response.data;
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			},
			async save() {
				this.page.saveErrors = [];
				this.page.saving = true;
				this.$v.$touch();

				if (this.$v.$invalid) {
					this.page.saveErrors.push('Please fix the errors below and try again.');
				} else {
					try {
						const response = await this.$http.post('admin/websitetext', this.data, this.getTokenHeader());
						this.log(response.data);
						this.$v.$reset();
						this.$refs.savedAlert.startAlert();
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.saveErrors = this.logError(error);
					}
				}

				this.page.saving = false;
			}
		}
	}
</script>