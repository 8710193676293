<template>
	<div>
		<auth-container :page="page" require-admin>
			<div>
				<h2 class="mb-4">Dataset details</h2>

				<b-tabs v-model="tabIndex" content-class="mt-4" class="page-nav-tabs">
					<b-tab title="Overview">
						<div class="lead text-center mb-4" v-if="!isNullOrEmpty(data.userName)">
							<span>
								The <strong>{{data.name}}</strong> dataset was uploaded {{data.createdOn | date}} by
								<router-link :to="`/admin/users/${data.userID}`">{{data.userName}}</router-link>.
							</span>
						</div>

						<b-row align-h="center">
							<b-col cols="6" lg="3">
								<div :class="`stat-box ${activeClass} text-white mb-2`" v-b-tooltip.hover title="An active status means the dataset can be used for new projects">
									<div class="title">Status: {{ data.isActive ? 'Active' : 'Inactive' }}</div>
									<div class="value">
										<font-awesome-icon v-if="data.isActive" :icon="['fas', 'check']" />
										<font-awesome-icon v-else :icon="['fas', 'times']" />
									</div>
								</div>
							</b-col>
							<b-col cols="6" lg="3">
								<div class="stat-box bg-info text-white mb-2">
									<div class="title">Subbasins</div>
									<div class="value">{{ data.counts.subbasins |  number(0) }}</div>
								</div>
							</b-col>
							<b-col cols="6" lg="3">
								<div class="stat-box bg-dark text-white mb-2" v-b-tooltip.hover title="Number of projects using this dataset">
									<div class="title">Projects</div>
									<div class="value">{{ data.counts.projects |  number(0) }}</div>
								</div>
							</b-col>
						</b-row>
					</b-tab>
					<b-tab title="Settings">
						<b-form>
							<error-list :errors="page.saveErrors"></error-list>
							<success-alert ref="savedAlert" text="Changes saved." />

							<b-form-group class="bg-light py-3 px-2 mb-4 border">
								<b-form-checkbox v-model.trim="$v.data.isActive.$model" switch>
									{{ data.isActive ? 'Active &mdash; new projects using this dataset are allowed' : 'Inactive &mdash; no new projects can be created using this dataset, but old projects already using it are still functional' }}
								</b-form-checkbox>
							</b-form-group>

							<b-form-group label="Name of the dataset (fewer than 50 characters)" :invalid-feedback="requiredFeedback($v.data.name)">
								<b-form-input v-model.trim="$v.data.name.$model" type="text" autofocus :state="getValidState($v.data.name)"></b-form-input>
							</b-form-group>

							<b-form-group label="Description (fewer than 200 characters)" :invalid-feedback="requiredFeedback($v.data.description)">
								<b-form-textarea v-model.trim="$v.data.description.$model" rows="4" :state="getValidState($v.data.description)"></b-form-textarea>
							</b-form-group>

							<b-form-group label="Agency, organization, or person to whom this dataset should be credited (optional)" :invalid-feedback="requiredFeedback($v.data.attribution)">
								<b-form-input v-model.trim="$v.data.attribution.$model" type="text" :state="getValidState($v.data.attribution)"></b-form-input>
							</b-form-group>

							<b-form-group label="Version" :invalid-feedback="requiredFeedback($v.data.version)">
								<b-form-input v-model.trim="$v.data.version.$model" type="number" step="any" :state="getValidState($v.data.version)"></b-form-input>
							</b-form-group>

							<hr class="mt-5 mb-4" />

							<h4>Advanced</h4>

							<p class="text-danger">
								Warning: changing the fields below could break the system.
								Please use caution when changing values.
							</p>

							<b-form-group class="my-4">
								<b-form-checkbox v-model.trim="$v.data.loadByDefault.$model">
									Load map of entire dataset area when selected?
									Recommended for small, user-submitted datasets, but not large, country-sized system datasets.
								</b-form-checkbox>
							</b-form-group>

							<b-row>
								<b-col>
									<b-form-group label="Minimum Latitude" :invalid-feedback="requiredFeedback($v.data.minLat)">
										<b-form-input v-model.trim="$v.data.minLat.$model" type="number" step="any" :state="getValidState($v.data.minLat)"></b-form-input>
									</b-form-group>
								</b-col>
								<b-col>
									<b-form-group label="Maximum Latitude" :invalid-feedback="requiredFeedback($v.data.maxLat)">
										<b-form-input v-model.trim="$v.data.maxLat.$model" type="number" step="any" :state="getValidState($v.data.maxLat)"></b-form-input>
									</b-form-group>
								</b-col>
							</b-row>
							<b-row>
								<b-col>
									<b-form-group label="Minimum Longitude" :invalid-feedback="requiredFeedback($v.data.minLon)">
										<b-form-input v-model.trim="$v.data.minLon.$model" type="number" step="any" :state="getValidState($v.data.minLon)"></b-form-input>
									</b-form-group>
								</b-col>
								<b-col>
									<b-form-group label="Maximum Longitude" :invalid-feedback="requiredFeedback($v.data.maxLon)">
										<b-form-input v-model.trim="$v.data.maxLon.$model" type="number" step="any" :state="getValidState($v.data.maxLon)"></b-form-input>
									</b-form-group>
								</b-col>
							</b-row>

							<hr class="my-4" />

							<b-form-group class="my-4">
								<b-form-checkbox v-model.trim="$v.data.restrictSelection.$model">
									Enable subbasin restriction to a certain area? Note: developer will need to set the selectable subbasins.
								</b-form-checkbox>
							</b-form-group>

							<b-form-group label="Message to user when clicking outside selectable area" :invalid-feedback="requiredFeedback($v.data.restrictionMessage)">
								<b-form-textarea v-model.trim="$v.data.restrictionMessage.$model" rows="4" :state="getValidState($v.data.restrictionMessage)"></b-form-textarea>
							</b-form-group>
						</b-form>
					</b-tab>
				</b-tabs>

				<fixed-action-bar :cols-lg="10" :offset-lg="2">
					<save-button v-if="tabIndex === settingsTab" @click.native="saveSettings" :saving="page.saving" class="mr-2" :disabled="!$v.data.$anyDirty" />
					<back-button class="btn btn-secondary mr-2" />

					<b-button variant="danger" class="ml-auto"
							  v-if="isAuthorized(roleNames.admin)"
							  @click="page.delete.show = true">Delete</b-button>
				</fixed-action-bar>

				<b-modal v-model="page.delete.show" size="md" title="Confirm delete" no-close-on-backdrop no-close-on-esc hide-header-close>
					<error-list :errors="page.delete.errors"></error-list>

					<p>
						Are you sure you want to delete this dataset?
						This action is permanent and cannot be undone.
						ALL projects and data will be deleted.
					</p>

					<div slot="modal-footer">
						<save-button type="button" :saving="page.delete.saving" @click.native="confirmDelete" text="Delete" variant="danger" />
						<b-button type="button" variant="secondary" @click="page.delete.show = false" class="ml-1">Cancel</b-button>
					</div>
				</b-modal>
			</div>
		</auth-container>
	</div>
</template>

<script>
	import { requiredIf, maxLength, between } from 'vuelidate/lib/validators';

	export default {
		name: 'AdminDatasetView',
		data() {
			return {
				datasetID: this.$route.params.id,
				page: {
					errors: [],
					loading: false,
					showLogin: false,
					saveErrors: [],
					saving: false,
					delete: {
						show: false,
						errors: [],
						saving: false
					}
				},
				data: {
					isActive: false,
					name: null,
					description: null,
					attribution: null,
					version: 1,
					counts: {},
					restrictSelection: false,
					restrictionMessage: null
				},
				tabIndex: 0,
				settingsTab: 1
			}
		},
		validations: {
			data: {
				isActive: {},
				name: {
					required: requiredIf(function () { return this.tabIndex == this.settingsTab; }),
					maxLength: maxLength(50)
				},
				description: {
					maxLength: maxLength(200)
				},
				attribution: {
					maxLength: maxLength(100)
				},
				version: { required: requiredIf(function () { return this.tabIndex == this.settingsTab; }) },
				minLat: {
					required: requiredIf(function () { return this.tabIndex == this.settingsTab; }),
					between: between(-180, 180)
				},
				maxLat: {
					required: requiredIf(function () { return this.tabIndex == this.settingsTab; }),
					between: between(-180, 180)
				},
				minLon: {
					required: requiredIf(function () { return this.tabIndex == this.settingsTab; }),
					between: between(-180, 180)
				},
				maxLon: {
					required: requiredIf(function () { return this.tabIndex == this.settingsTab; }),
					between: between(-180, 180)
				},
				loadByDefault: {},
				restrictSelection: {},
				restrictionMessage: {}
			}
		},
		async created() {
			await this.get();
		},
		watch: {
			'$route': 'get'
		},
		methods: {
			async get() {
				this.page.errors = [];
				this.page.loading = true;
				this.datasetID = this.$route.params.id;

				try {
					const response = await this.$http.get(`admin/datasets/${this.datasetID}`, this.getTokenHeader());
					this.log(response.data);
					this.data = response.data.dataset;
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			},
			async saveSettings() {
				this.page.saveErrors = [];
				this.page.saving = true;

				if (this.$v.$invalid) {
					this.$v.$touch();
					this.page.saveErrors.push('Please fix the errors below and try again.');
				} else {
					try {
						var data = {
							id: this.datasetID,
							isActive: this.data.isActive,
							name: this.data.name,
							description: this.data.description,
							attribution: this.data.attribution,
							version: this.data.version,
							loadByDefault: this.data.loadByDefault,
							maxLat: this.data.maxLat,
							minLat: this.data.minLat,
							maxLon: this.data.maxLon,
							minLon: this.data.minLon,
							restrictSelection: this.data.restrictSelection,
							restrictionMessage: this.data.restrictionMessage
						};
						await this.$http.put(`admin/datasets/${this.datasetID}`, data, this.getTokenHeader());
						this.$refs.savedAlert.startAlert();
						this.$v.$reset();
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.saveErrors = this.logError(error);
					}
				}

				this.page.saving = false;
			},
			async confirmDelete() {
				this.page.delete.errors = [];
				this.page.delete.saving = true;

				try {
					await this.$http.delete(`admin/datasets/${this.datasetID}`, this.getTokenHeader());
					this.$router.push({ name: 'AdminDatasets' }).catch(err => { this.log(err); });
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.delete.errors = this.logError(error);
				}

				this.page.delete.saving = false;
			}
		},
		computed: {
			activeClass() {
				return this.data.isActive ? 'bg-success' : 'bg-danger';
			}
		}
	}
</script>
