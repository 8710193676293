<template>
	<auth-container :page="page" require-admin>
		<h2 class="mb-4">Edit user</h2>

		<success-alert ref="setApprovedAlert" text="User account approved. The user has been sent an email notification." />
		<div v-if="profile.isPending">
			<error-list :errors="page.approve.errors"></error-list>
			<error-list :errors="page.deleteAccount.errors"></error-list>

			<b-alert variant="warning" show>
				<p>This account is pending approval.</p>

				<save-button :saving="page.approve.saving" text="Approve" @click.native="approveAccount" class="mr-2" />
				<save-button :saving="page.deleteAccount.saving" variant="danger" text="Deny/Delete" @click.native="confirmDelete(false)" />
			</b-alert>
		</div>

		<error-list :errors="page.lockout.errors"></error-list>
		<div v-if="profile.isLockedOut">
			<b-alert variant="warning" show>
				<p>This account is locked out until {{profile.lockoutEndDate | date}}</p>
				<save-button :saving="page.lockout.saving" text="Unlock account" @click.native="setLockout(-1)" class="mr-2" />
			</b-alert>
		</div>

		<b-tabs v-model="tabIndex" content-class="mt-4" class="page-nav-tabs">
			<b-tab title="Profile">
				<error-list :errors="page.profile.errors"></error-list>
				<success-alert ref="profileSavedAlert" text="Changes saved." />

				<error-list :errors="page.setRole.errors"></error-list>
				<success-alert ref="setRoleAlert" text="User role change saved." />

				<b-row>
					<b-col md="8">
						<b-form>
							<b-form-group label="User name" :invalid-feedback="requiredFeedback($v.profile.userName)">
								<b-form-input v-model.trim="$v.profile.userName.$model" type="text" autofocus :state="getValidState($v.profile.userName)"></b-form-input>
							</b-form-group>

							<b-form-group label="Email">
								<b-form-input v-model.trim="$v.profile.email.$model" type="email" :state="getValidState($v.profile.email)"></b-form-input>
								<b-form-invalid-feedback v-if="!$v.profile.email.required">Required</b-form-invalid-feedback>
								<b-form-invalid-feedback v-if="!$v.profile.email.email">Please enter a valid email address</b-form-invalid-feedback>
							</b-form-group>

							<b-row>
								<b-col md>
									<b-form-group label="First name" :invalid-feedback="requiredFeedback($v.profile.firstName)">
										<b-form-input v-model.trim="$v.profile.firstName.$model" type="text" :state="getValidState($v.profile.firstName)"></b-form-input>
									</b-form-group>
								</b-col>
								<b-col md>
									<b-form-group label="Last name" :invalid-feedback="requiredFeedback($v.profile.lastName)">
										<b-form-input v-model.trim="$v.profile.lastName.$model" type="text" :state="getValidState($v.profile.lastName)"></b-form-input>
									</b-form-group>
								</b-col>
							</b-row>

							<b-form-group label="Company or organization" :invalid-feedback="requiredFeedback($v.profile.affiliation)">
								<b-form-input v-model.trim="$v.profile.affiliation.$model" type="text" :state="getValidState($v.profile.affiliation)"></b-form-input>
							</b-form-group>

							<b-form-group label="Category" :invalid-feedback="requiredFeedback($v.profile.organizationCategory)">
								<b-form-select v-model.trim="$v.profile.organizationCategory.$model" :options="location.organizationCategories" :state="getValidState($v.profile.organizationCategory)"></b-form-select>
							</b-form-group>

							<b-form-group label="What is your reason for trying SELECT?" :invalid-feedback="requiredFeedback($v.profile.reasonForUse)">
								<b-form-input v-model.trim="$v.profile.reasonForUse.$model" type="text" :state="getValidState($v.profile.reasonForUse)"></b-form-input>
							</b-form-group>

							<b-form-group label="In what country are you located?" :invalid-feedback="requiredFeedback($v.profile.country)">
								<b-form-select v-model.trim="$v.profile.country.$model" :options="location.countries" :state="getValidState($v.profile.country)"></b-form-select>
							</b-form-group>

							<b-form-group v-if="profile.country === 'United States'" label="Select your state or territory" :invalid-feedback="requiredFeedback($v.profile.state)">
								<b-form-select v-model.trim="$v.profile.state.$model" :options="location.states" :state="getValidState($v.profile.state)"></b-form-select>
							</b-form-group>

							<b-form-group v-if="isAuthorized(roleNames.superAdmin) || !profile.isSuperAdmin">
								<b-checkbox v-model.trim="$v.profile.isAdmin.$model">Is system administrator?</b-checkbox>
								<b-checkbox v-if="isAuthorized(roleNames.superAdmin)" v-model.trim="$v.profile.isSuperAdmin.$model">Is full system administrator?</b-checkbox>
							</b-form-group>
							<div v-else>
								<small>Note: this user is a super administrator and their data may only be changed by another super administrator.</small>
							</div>
						</b-form>
					</b-col>
					<b-col>
						<div class="text-muted text-small">
							<div>Last log-in on {{profile.lastLoginDate | date}}</div>
							<div>Registered on {{profile.registrationDate | date}}</div>
						</div>
					</b-col>
				</b-row>
			</b-tab>

			<b-tab title="Reset password" v-if="isAuthorized(roleNames.superAdmin) || !profile.isSuperAdmin">
				<error-list :errors="page.password.errors"></error-list>
				<success-alert ref="passwordSavedAlert" text="Changes saved." />

				<b-form>
					<p>
						In most cases you should instruct the user to use the forgot password link under the log-in form.
						They will enter their email and be mailed instructions for resetting.
						In cases where this doesn't work or is not an option, enter a new password below and send it to the user.
						Please instruct them to change it after successful log-in.
					</p>

					<b-form-group label="New password" :description="globals.passwordRequirements">
						<b-form-input v-model.trim="$v.password.new.$model" type="password" :state="getValidState($v.password.new)"></b-form-input>
						<b-form-invalid-feedback v-if="!$v.password.new.required">Required</b-form-invalid-feedback>
						<b-form-invalid-feedback v-if="!$v.password.new.strongPassword">{{globals.passwordRequirements}}</b-form-invalid-feedback>
					</b-form-group>

					<b-form-group label="Confirm new password">
						<b-form-input v-model.trim="$v.password.confirm.$model" type="password" :state="getValidState($v.password.confirm)"></b-form-input>
						<b-form-invalid-feedback v-if="!$v.password.confirm.required">Required</b-form-invalid-feedback>
						<b-form-invalid-feedback v-if="!$v.password.confirm.sameAsPassword">Passwords must match</b-form-invalid-feedback>
					</b-form-group>
				</b-form>
			</b-tab>

			<b-tab title="Delete account" title-link-class="text-danger" v-if="isAuthorized(roleNames.superAdmin) || !profile.isSuperAdmin">
				<div v-if="!isAuthorized(roleNames.superAdmin) && profile.isSuperAdmin">
					Your account does not have permission to delete this user.
				</div>
				<div v-else>
					<p>
						Are you sure you want to delete this account?
						This action is <strong>permanent and cannot be undone</strong>.
					</p>

					<error-list :errors="page.deleteAccount.errors"></error-list>

					<b-form>
						<b-form-group label="Type DELETE to confirm">
							<b-form-input v-model="deleteAccount.value" type="text" :state="getValidState($v.deleteAccount.value)"></b-form-input>
							<b-form-invalid-feedback v-if="!$v.deleteAccount.value.required">Required</b-form-invalid-feedback>
							<b-form-invalid-feedback v-if="deleteAccount.value !== 'DELETE'">You must type DELETE</b-form-invalid-feedback>
						</b-form-group>
					</b-form>
				</div>
			</b-tab>
		</b-tabs>

		<fixed-action-bar :cols-lg="10" :offset-lg="2">
			<save-button v-if="tabIndex == 0 && (isAuthorized(roleNames.superAdmin) || !profile.isSuperAdmin)" :saving="page.profile.saving" @click.native="saveProfile" class="mr-2" :disabled="!$v.profile.$anyDirty" />
			<save-button v-if="tabIndex == 1" :saving="page.password.saving" @click.native="savePassword" class="mr-2" :disabled="!$v.password.$anyDirty" />
			<save-button v-if="tabIndex == 2" :saving="page.deleteAccount.saving" @click.native="confirmDelete" class="mr-2"
						 variant="danger" text="Delete Account"
						 :disabled="deleteAccount.value !== 'DELETE' || (!isAuthorized(roleNames.superAdmin) && profile.isSuperAdmin)" />
			<back-button class="btn btn-secondary mr-2" />
			<save-button v-if="!profile.isLockedOut && (isAuthorized(roleNames.superAdmin) || !profile.isSuperAdmin)" :saving="page.lockout.saving"
						 variant="warning" text="Lockout User"
						 v-b-tooltip.hover title="Lockout user account from accessing this app for 30 days"
						 @click.native="setLockout(30)" class="ml-auto" />
		</fixed-action-bar>
	</auth-container>
</template>

<script>
	import { requiredIf, email, sameAs } from 'vuelidate/lib/validators';

	export default {
		name: 'UserEdit',
		data() {
			return {
				userID: this.$route.params.id,
				page: {
					errors: [],
					loading: false,
					showLogin: false,
					profile: {
						errors: [],
						saving: false
					},
					password: {
						errors: [],
						saving: false
					},
					deleteAccount: {
						errors: [],
						saving: false
					},
					approve: {
						errors: [],
						saving: false
					},
					lockout: {
						errors: [],
						saving: false
					},
					setRole: {
						errors: [],
						saving: false
					}
				},
				tabIndex: 0,
				profile: {},
				stats: {
					statisticsTimeChart: {}
				},
				password: {
					new: '',
					confirm: ''
				},
				deleteAccount: {
					value: ''
				},
				location: {
					countries: [],
					states: [],
					organizationCategories: []
				}
			}
		},
		validations: {
			profile: {
				userName: { required: requiredIf(function(){ return this.tabIndex == 0; }) },
				firstName: { required: requiredIf(function(){ return this.tabIndex == 0; }) },
				lastName: { required: requiredIf(function(){ return this.tabIndex == 0; }) },
				country: { required: requiredIf(function () { return this.tabIndex == 0; }) },
				state: { required: requiredIf(function () { return this.tabIndex == 0 && this.profile.country == 'United States'; }) },
				organizationCategory: { required: requiredIf(function () { return this.tabIndex == 0; }) },
				affiliation: { required: requiredIf(function () { return this.tabIndex == 0; }) },
				reasonForUse: { required: requiredIf(function () { return this.tabIndex == 0; }) },
				isAdmin: {},
				isSuperAdmin: {},
				email: {
					email,
					required: requiredIf(function(){ return this.tabIndex == 0; })
				}
			},
			password: {
				new: {
					strongPassword(password) {
						return (
							this.tabIndex != 1 || (
							/[a-z]/.test(password) && // checks for a-z
							/[0-9]/.test(password) && // checks for 0-9
							/\W|_/.test(password) && // checks for special char
							password.length >= 10)
						);
					},
					required: requiredIf(function(){ return this.tabIndex == 1; })
				},
				confirm: {
					sameAsPassword: sameAs('new'),
					required: requiredIf(function(){ return this.tabIndex == 1; })
				}
			},
			deleteAccount: {
				value: { required: requiredIf(function(){ return this.tabIndex == 2; }) }
			}
		},
		async created() {
			await this.get();
		},
		watch: {
			'$route': 'get'
		},
		methods: {
			async get() {
				this.page.errors = [];
				this.page.loading = true;
				this.userID = this.$route.params.id;

				try {
					const response = await this.$http.get(`users/${this.userID}`, this.getTokenHeader());
					this.log(response.data);
					this.profile = response.data.profile;
					this.location = response.data.location;
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			},
			async saveProfile() {
				this.page.profile.errors = [];
				this.page.profile.saving = true;

				if (this.$v.profile.$invalid) {
					this.page.profile.errors.push('Please fix the errors below and try again.');
					this.log(this.$v);
				} else {
					try {
						const response = await this.$http.put(`users/${this.userID}`, this.profile, this.getTokenHeader());
						this.log(response.data);
						this.$refs.profileSavedAlert.startAlert();
						this.$v.$reset();
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.profile.errors = this.logError(error);
					}
				}

				this.page.profile.saving = false;
			},
			async savePassword() {
				this.page.password.errors = [];
				this.page.password.saving = true;

				if (this.$v.$invalid) {
					this.page.password.errors.push('Please fix the errors below and try again.');
				} else {
					try {
						var data = {
							value: this.password.new
						};
						const response = await this.$http.post(`users/password/${this.userID}`, data, this.getTokenHeader());
						this.log(response.data);
						this.$refs.passwordSavedAlert.startAlert();
						this.$v.$reset();
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.password.errors = this.logError(error);
					}
				}

				this.page.password.saving = false;
			},
			async confirmDelete() {
				this.page.deleteAccount.errors = [];
				this.page.deleteAccount.saving = true;

				if (this.$v.$invalid) {
					this.page.deleteAccount.errors.push('Please fix the errors below and try again.');
				} else {
					try {
						const response = await this.$http.delete(`account/delete?userId=${this.userID}`, this.getTokenHeader());
						this.log(response.data);
						this.$router.push({ name: 'UserList' }).catch(err => { this.log(err); });
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.deleteAccount.errors = this.logError(error);
					}
				}

				this.page.deleteAccount.saving = false;
			},
			async approveAccount() {
				this.page.approve.errors = [];
				this.page.approve.saving = true;

				try {
					await this.$http.get(`users/approve?id=${this.userID}`, this.getTokenHeader());
					await this.get();
					this.$refs.setApprovedAlert.startAlert();
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.approve.errors = this.logError(error);
				}

				this.page.approve.saving = false;
			},
			async setLockout(days) {
				this.page.lockout.errors = [];
				this.page.lockout.saving = true;

				try {
					const response = await this.$http.get(`users/setlock/${this.userID}/${days}`, this.getTokenHeader());
					this.log(response.data);
					await this.get();
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.lockout.errors = this.logError(error);
				}

				this.page.lockout.saving = false;
			}
		}
	}
</script>