<template>
	<div>
		<auth-container :page="page" require-admin>
			<b-container fluid class="p-0">
				<b-row no-gutters>
					<b-col md="3" lg="2" order="2" order-md="1" class="fixed-height bg-light">
						<div class="px-3 pt-4">
							<b-nav vertical class="dash-items-nav nav-lg">
								<b-nav-item to="/admin/projects">
									<font-awesome-icon :icon="['fas', 'project-diagram']" fixed-width class="mr-3" /> Projects
								</b-nav-item>
								<b-nav-item to="/admin/users">
									<font-awesome-icon :icon="['fas', 'user-friends']" fixed-width class="mr-3" /> Users
								</b-nav-item>
								<b-nav-item to="/admin/errors">
									<font-awesome-icon :icon="['fas', 'exclamation-triangle']" fixed-width class="mr-3" /> Feedback/errors
								</b-nav-item>
								<b-nav-item to="/admin/website">
									<font-awesome-icon :icon="['fas', 'cog']" fixed-width class="mr-3" /> Website config
								</b-nav-item>
								<b-nav-item to="/admin/datasets">
									<font-awesome-icon :icon="['fas', 'database']" fixed-width class="mr-3" /> Datasets
								</b-nav-item>
							</b-nav>
						</div>
					</b-col>
					<b-col md="9" lg="10" order="1" order-md="2" class="fixed-height shadow-sm no-shadow-sm" style="z-index:500">
						<b-breadcrumb :items="breadCrumbs" class="bg-white px-md-4 mb-0"></b-breadcrumb>
						<div class="container-fluid px-md-4 pt-2 pb-4">
							<div v-if="$route.name === 'AdminDashboard'">
								<div class="text-muted mb-3 border-bottom pb-2">
									<small>
										<font-awesome-icon :icon="['far', 'clock']" class="mr-1" />
										The statistics on this page were computed at
										{{ data.computedAt | date }}.
										<a href="#" @click.prevent="get">Refresh now.</a>
									</small>
								</div>

								<div class="mb-4">
									<highcharts :options="statisticsTimeChart"></highcharts>
								</div>

								<h2 class="h4 text-center">Totals</h2>
								<hr class="mt-2 mb-3" />
								<b-row align-h="center">
									<b-col cols="4" lg="3">
										<div class="stat-box bg-dark text-white mb-2">
											<div class="title">Users</div>
											<div class="value">{{ data.numUsers |  number(0) }}</div>
										</div>
									</b-col>
									<b-col cols="4" lg="3">
										<div class="stat-box bg-dark text-white mb-2">
											<div class="title">Projects</div>
											<div class="value">{{ data.numProjects |  number(0) }}</div>
										</div>
									</b-col>
									<b-col cols="4" lg="3">
										<div class="stat-box bg-dark text-white mb-2">
											<div class="title">Projects / User</div>
											<div class="value">{{ data.avgProjectsPerUser |  number(1) }}</div>
										</div>
									</b-col>
								</b-row>

								<div v-if="isAuthorized(roleNames.superAdmin)" class="mt-4">
									<h2 class="h4 text-center">Export SELECT Data</h2>
									<hr class="mt-2 mb-3" />

									<success-alert ref="savedAlert" text="Export complete." />
									<error-list :errors="page.saveErrors"></error-list>

									<b-form inline>
										<b-form-select v-model="exporting.selectedDatasetId" :options="exporting.datasets" class="mr-1">
											<template #first>
												<b-form-select-option :value="null" disabled>-- Please select a dataset --</b-form-select-option>
											</template>
										</b-form-select>
										<save-button :saving="page.saving" type="button" @click.native="exportData" text="Export Data" />
									</b-form>

									<h2 class="h4 text-center mt-4">Import SELECT Data</h2>
									<hr class="mt-2 mb-3" />

									<success-alert ref="savedAlert2" text="Import complete." />
									<error-list :errors="page.saveErrors2"></error-list>
									<p v-if="page.errorDetails2 !== null">
										<b-form-textarea v-model="page.errorDetails2" rows="7"></b-form-textarea>
									</p>

									<vue-dropzone ref="addFile" id="addFile" :options="dropzoneOptions" @vdropzone-success="fileUploaded" @vdropzone-error="uploadError"></vue-dropzone>
								</div>
							</div>
							<router-view></router-view>
						</div>
					</b-col>
				</b-row>
			</b-container>
		</auth-container>
	</div>
</template>

<script>
	import vue2Dropzone from 'vue2-dropzone';
	import 'vue2-dropzone/dist/vue2Dropzone.min.css';

	export default {
		name: 'AdminDashboard',
		components: {
			vueDropzone: vue2Dropzone
		},
		data() {
			return {
				page: {
					errors: [],
					loading: false,
					showLogin: false,
					saving: false,
					saveErrors: [],
					saveErrors2: [],
					errorDetails2: null
				},
				data: {
					statisticsTimeChart: {}
				},
				exporting: {
					datasets: [],
					selectedDatasetId: null
				},
				dropzoneOptions: {
					url: '/api/import/import/',
					headers: this.getTokenHeader().headers,
					acceptedFiles: '.7z,.zip',
					timeout: 3600000,
					maxFilesize: 1000
				}
			}
		},
		async created() {
			await this.get();
		},
		methods: {
			async get() {
				this.page.errors = [];
				this.page.loading = true;

				try {
					const response = await this.$http.get('admin/dashboard', this.getTokenHeader());
					this.log(response.data);
					this.data = response.data;

					const response2 = await this.$http.get('/maps/datasets', this.getTokenHeader());
					this.exporting.datasets = response2.data;
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			},
			async exportData() {
				this.page.saveErrors = [];
				this.page.saving = true;

				if (this.exporting.selectedDatasetId === null) {
					this.page.saveErrors.push('Please select a dataset.');
				} else {
					try {
						const response = await this.$http.post('import/export', { value: this.exporting.selectedDatasetId }, this.getTokenHeader());
						this.log(response);
						this.$refs.savedAlert.startAlert();
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.saveErrors = this.logError(error);
					}
				}

				this.page.saving = false;
			},
			async fileUploaded(file, response) {
				this.log(file);
				this.log(response);
				this.page.saveErrors2 = [];
				this.page.errorDetails2 = null;
				this.$refs.savedAlert2.startAlert();
			},
			uploadError(file, message, xhr) {
				this.log(file);
				this.log(xhr);
				this.log(message);
				this.page.saveErrors2.push(message.errors.exception[0]);
				this.page.errorDetails2 = message.stackTrace;
			}
			/*async importData() {
				this.page.saveErrors = [];
				this.page.saving = true;

				try {
					const response = await this.$http.post('import/data', {}, this.getTokenHeader());
					this.log(response);
					this.$refs.savedAlert.startAlert();
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.saveErrors = this.logError(error);
				}

				this.page.saving = false;
			}*/
		},
		computed: {
			breadCrumbs() {
				var crumbs = [];
				for (let item of this.$route.matched) {
					let name = item.meta.title ? item.meta.title : item.name;

					crumbs.push({
						text: name,
						to: { name: item.name }
					});
				}
				return crumbs;
			},
			statisticsTimeChart() {
				return {
					chart: { type: 'bar' },
					plotOptions: { bar: { dataLabels: { enabled: true } } },
					title: { text: this.data.statisticsTimeChart.title },
					xAxis: { categories: this.data.statisticsTimeChart.xAxisCategories },
					yAxis: { title: { text: '' } },
					series: this.data.statisticsTimeChart.series
				};
			}
		}
	}
</script>